import React, {useEffect, useRef, useState} from "react";
import {clear} from "@testing-library/user-event/dist/clear";
import excelIcon from '../../assets/icon/excel.svg';
import {isIterableArray, useQuery} from "../../helpers/common";
import {Col, Row} from "reactstrap";
import arrowIcon from '../../assets/icon/arrow.svg';
import {WorkExcelModal} from "./WorkExcelModal";
import {
    collection,
    getDocs,
    updateDoc,
    getDoc,
    doc,
    query,
    where,
    increment,
    addDoc,
    Timestamp
} from "firebase/firestore";
import axios from "axios";
import {firestore} from "../../firebase";
import {ExcelDown} from "./ExcelDown";
import {useDispatch} from "react-redux";
import {showSnackBarDialog} from "../snackBar/action";
import {CircularProgress} from "@mui/material";
import {MapLoader} from "../workMode/MapLoader";
import {downloadExcelForm} from "../../helpers/MobileFunction";

// import {XLSX} from "xlsx";
const xlsxFile = require("read-excel-file/node");
const XLSX = require("xlsx");


export const WorkExcel = () => {
    const dispatch = useDispatch();
    let queryString = useQuery();
    const inputFileRef = useRef(null);
    const [jsonData, setJsonData] = useState([]);
    const [fileName, setFileName] = useState("");
    const [successIndex, setSuccessIndex] = useState([]);
    const [failIndex, setFailIndex] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [excelReview, setExcelReview] = useState({});
    const [isFinish, setIsFinish] = useState(false);
    const [projectId, setProjectId] = useState("");
    const id = queryString.get('id');
    const [loader, setLoader] = useState(false);
    const [listLoader, setListLoader] = useState(false);
    const initState = () => {
        inputFileRef.current.value = '';
        setJsonData([]);
        setFileName("");
        setFailIndex([]);
        setSuccessIndex([]);
        setIsFinish(false);
    }


    useEffect(() => {
        if (id) {
            setProjectId(id);
        } else {
            //TODO 존재하지 않는 프로젝트 아이디
        }
    }, [id]);


    useEffect(() => {

        return () => {
            initState()
        }
    }, [])


    const getPolygonToAddress = async (post) => {
        return await axios.post(`https://asia-northeast3-bluesky-d6001.cloudfunctions.net/polygonToAddress`, post, {
            headers: {
                "Content-Type": 'application/json',
                "Accept": "*/*"
            }
        }).catch(err => {
            throw Error("주소 인식에 실패했습니다. 다른 주소를 등록해주세요.")
        });

    }
    const getArea = (polygonArray) => {
        const area = window.google.maps.geometry.spherical.computeArea(polygonArray);
        if (area) {
            return parseFloat((area * 0.3025).toFixed(2));
        }

    }
    const createWork = async (projectId, address, farmerName, farmerPhone, request) => {

        try {
            const polygonResult = await getPolygonToAddress({data: {address: address}});

            const locationLatLng = polygonResult.data.data.latLng;
            let newPolygon = [];
            let areaPolygon = [];
            if (isIterableArray(polygonResult.data.data.polygon)) {
                newPolygon = polygonResult.data.data.polygon.map(pl => ({latitude: pl[1], longitude: pl[0]}))
                areaPolygon = polygonResult.data.data.polygon.map(pl => ({lat: pl[1], lng: pl[0]}));
            }

            const workQuery = query(collection(firestore, "works"),
                where("latLng", "==", locationLatLng),
                where("projectId", "==", projectId));
            const workSnapshot = await getDocs(workQuery);
            const projectIds = workSnapshot.docs.map(document => document.data().projectId);
            if (projectIds) {
                if (isIterableArray(projectIds)) {
                    return "이미 등록된 주소입니다.";
                }
            }
            // for (const projectId of projectIds) {
            //     const projectRef = doc(firestore, "projects", projectId);
            //     const projectSnap = await getDoc(projectRef);
            //     if (projectSnap.exists()) {
            //         if (projectSnap.data().enabled === true) {
            //             return "이미 등록된 주소입니다.";
            //         }
            //     }
            // }

            const workRef = collection(firestore, "works");
            const setDocResult = await addDoc(workRef, {
                projectId: projectId,
                address: polygonResult.data.data.address.join(" "),
                addressList: polygonResult.data.data.address,
                farmerName: farmerName,
                farmerPhone: farmerPhone,
                workerId: null,
                workType: "드론",
                workStatus: "예정",
                request: request ? String(request) : "",
                latLng: locationLatLng,
                enabled: 1,
                area: getArea(areaPolygon),
                polygon: newPolygon,
                created: Timestamp.now()
            });

            let addProjectData = {
                "workStatus.expected.area": increment(getArea(areaPolygon)),
                "workStatus.expected.cnt": increment(1)
            }
            addProjectData[`addressList.${setDocResult.id}`] = polygonResult.data.data.address.join("");
            const projectRef = doc(firestore, "projects", projectId);
            await updateDoc(projectRef, addProjectData);


            return "ok";


        } catch (e) {
            if (e.message) {
                if (e.message === "주소 인식에 실패했습니다. 다른 주소를 등록해주세요.") {
                    return "주소 인식에 실패했습니다. 다른 주소를 등록해주세요.";
                } else if (e.message === "이미 등록된 주소입니다.") {
                    return "이미 등록된 주소입니다.";
                } else return "서버에 문제가 발생했습니다. 잠시 후 이용해주세요.";
            } else return "서버에 문제가 발생했습니다. 잠시 후 이용해주세요.";
        }


    }

    const toggle = () => {
        setShowModal(!showModal);
    }
//
    const upload = (e) => {
        setListLoader(true);
        if (e.target.files?.item(0)) {
            const file = e.target.files[0];
            if (!checkFileType(file)) {
                dispatch(showSnackBarDialog(true, "엑셀 파일이 아닙니다. .xlsx 확장자로 입력해주세요."));
                clear();
                setListLoader(false);
                return;
            }
            const reader = new FileReader();
            reader.onload = (evt) => {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, {type: "binary"});
                let jsonArr = [];

                if (isIterableArray(wb.SheetNames)) {
                    wb.SheetNames.forEach(wsName => {
                        const ws = wb.Sheets[wsName];
                        const datas = XLSX.utils.sheet_to_json(ws, {header: 0, range: 1});
                        const aCell = ws?.A2?.v;
                        const bCell = ws?.B2?.v;
                        const cCell = ws?.C2?.v;
                        const dCell = ws?.D2?.v;
                        const titleCell = [aCell, bCell, cCell, dCell];
                        if (!((titleCell.includes("주소")) && (titleCell.includes("성명")) && (titleCell.includes("전화번호")) && (titleCell.includes("요청사항")))) {
                            dispatch(showSnackBarDialog(true, `잘못된 양식에 엑셀 파일입니다. ${wsName} 시트에 양식을 확인하여 주세요.`));
                            jsonArr = []
                            setListLoader(false);
                            return;
                        }


                        if (!isIterableArray(datas)) {
                            initState();
                            setListLoader(false);
                            return;
                        }

                        datas.forEach((data, index) => {
                            jsonArr.push({
                                index: index,
                                address: data["주소"],
                                farmerName: data["성명"],
                                farmerPhone: data["전화번호"],
                                request: data["요청사항"]
                            })
                        })
                    })
                }
                setJsonData(jsonArr);
                setFileName(file?.name);
            };
            reader.readAsBinaryString(file);
            inputFileRef.current.value = '';
            setListLoader(false);
        }
    }


    const uploadExcel = async () => {
        try {
            if (!isIterableArray(jsonData)) {
                dispatch(showSnackBarDialog(true, '엑셀파일을 등록하여 주세요.'));
                return;
            }
            setLoader(true);
            for (const json of jsonData) {
                const result = await createWork(id, json.address, json.farmerName, json.farmerPhone, json.request);
                if (result === "ok") {
                    setSuccessIndex(prevState => [...prevState, json.index]);
                } else if (result === "주소 인식에 실패했습니다. 다른 주소를 등록해주세요.") {
                    setFailIndex(prevState => [...prevState, {
                        index: json.index,
                        message: "주소 인식에 실패했습니다. 다른 주소를 등록해주세요."
                    }]);
                } else if (result === "이미 등록된 주소입니다.") {
                    setFailIndex(prevState => [...prevState, {index: json.index, message: "이미 등록된 주소입니다."}]);
                } else {
                    setFailIndex(prevState => [...prevState, {
                        index: json.index,
                        message: "서버에 문제가 발생했습니다. 잠시 후 이용해주세요."
                    }]);
                }
            }
            setLoader(false);
            setIsFinish(true);
            dispatch(showSnackBarDialog(true, '작업추가가 모두 완료되었습니다.'));
        } catch (e) {
            if (e) {
                setLoader(false);
            }
        }
    }

    const checkFileType = (file) => {
        const filename = file.name;
        const _lastDot = filename.lastIndexOf('.');
        const _fileExt = filename.substring(_lastDot, filename.length).toLowerCase();
        return _fileExt === '.xlsx';
    }

    const readFile = (file, callback) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = (e) => {
            const data = e.target?.result;
            const arr = fixData(data);
            const workbook = XLSX.read(btoa(arr), {type: 'base64'});
            callback(workbook);
        }
    }

    const fixData = (data) => {
        let o = "", l = 0, w = 10240;
        for (l; l < data.byteLength / w; ++l) {
            o += String.fromCharCode.apply(null, new Uint8Array(data.slice(1 * w, 1 * w + w)))
        }
        o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
        return o;
    }

    const workbookToJsonArray = (workbook) => {
        let jArray = [];
        workbook.SheetNames.forEach(function (sheetName) {
            const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {});
            if (roa.length > 0) jArray.push(roa);
        });
        return jArray[0];
    }

    const handleSelectWork = (index, status) => {
        let excelReviewData = jsonData[index]
        if (status === "실패") {
            const findFailData = failIndex.filter(failData => failData.index === index);
            if (isIterableArray(findFailData)) {
                excelReviewData.errorMsg = findFailData[0].message;
            }

        }
        excelReviewData.status = status
        setExcelReview(excelReviewData);
        toggle();
    }

    return (
        <div className='work_excel_wrapper'>
            <div>
                <div className='work_excel_title'>
                    <span className={'fw-bold'}>엑셀 파일 등록</span>
                    <div>
                        <button className='down_excel_form_btn' style={{marginRight: '1rem'}} onClick={initState}>초기화
                        </button>
                        <ExcelDown/>
                    </div>

                </div>

                <div className='excel_input_wrapper' onClick={() => inputFileRef.current.click()}>
                    <div className='ab_ct'>
                        {!isIterableArray(jsonData) ?
                            <>
                                <img src={excelIcon} alt={'excel_icon'}/>
                                <span className='mt_txt'>엑셀 파일을 등록해주세요.</span>
                                <small className={'text-center'}>우측 상단에 양식을 확인하여 양식에 맞는 <br/> 엑셀 파일을 등록하여 주세요.</small>
                            </>
                            :
                            <>
                                <img src={excelIcon} alt={'excel_icon'}/>
                                <span className='mt_txt'>{fileName}이 등록되었습니다.</span>
                                <span>하단에 <strong>추가버튼</strong>을 눌러 추가하여 주세요.</span>
                            </>
                        }

                    </div>
                    <input style={{display: "none"}} onChange={upload} ref={inputFileRef} type={'file'}/>
                </div>
                {listLoader ?
                    <div className='excel_list_loader'>
                        <CircularProgress/>
                    </div>
                    :
                    <>
                        {isIterableArray(jsonData) &&
                            <div className='work_add_list_wrapper'>
                                {jsonData.map((work, index) => {
                                    const addStatus = successIndex.some(successId => successId === index) ? '성공' :
                                        failIndex.some(failId => failId.index === index) ? '실패' : '대기중'
                                    return (
                                        <Row
                                            onClick={() => handleSelectWork(index, addStatus)}
                                            key={index}
                                            className={successIndex.some(successId => successId === index) ? 'work_add_item success' :
                                                failIndex.some(failId => failId.index === index) ? 'work_add_item failed' : 'work_add_item suspend'}>
                                            <Col className='p-0 item_address_txt' xs={9}>
                                                <span>{work?.address}</span>
                                            </Col>
                                            <Col className='p-0 flex_center' xs={2}>
                                <span className='work_status_txt fw-bold'>
                                {addStatus}
                                </span>
                                            </Col>
                                            <Col className='p-0 flex_center' xs={1}>
                                                <img className='work_item_arrow' src={arrowIcon} alt={'arrow'}
                                                     width={10}/>
                                            </Col>
                                        </Row>
                                    )
                                })
                                }
                            </div>
                        }
                    </>
                }
            </div>
            <div className='excel_add_btn_wrapper'>
                {isFinish ?
                    <button className='finish_btn' disabled={loader}>
                        <span>작업추가 완료</span>
                    </button>
                    :
                    <button disabled={loader} onClick={uploadExcel}>
                        {loader ?
                            <CircularProgress style={{color: 'white', maxWidth: '20px', maxHeight: '20px'}}/>
                            :
                            <span>추가</span>
                        }

                    </button>
                }

            </div>
            <WorkExcelModal modal={showModal} toggle={toggle} excel={excelReview}/>
        </div>
    )
}
