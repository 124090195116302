import React, {useEffect, useState} from "react";
import DialogSheet from "../common/DialogSheet";
import {useDispatch, useSelector} from "react-redux";
import {showUserDialog} from "./action";
import phoneCallIcon from '../../assets/icon/phoneCall.svg';
import {callPhone, sendMMSWorker} from "../../helpers/MobileFunction";
import mailIcon from "../../assets/icon/mail.svg";

const UserDialog = () => {
    const dispatch = useDispatch()
    const {showUser, selectUser} = useSelector(state => state.dialog);
    const handleDismissDialog = () => {
        dispatch(showUserDialog({}));
    }


    return (
        <DialogSheet height={0.7} open={showUser} dismiss={handleDismissDialog}>
            <div style={{padding : '0.5rem 1rem'}}>
                <span className='d-block fw-bold'>작업자 정보</span>
            </div>
            {Object.keys(selectUser).length !== 0 ?
                <div className='user_dialog_wrapper'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <span className='d-block fw-bold'>이름</span>
                            <span className='d-block fw-bold'>전화번호</span>
                        </div>
                        <div style={{marginLeft: '0.3rem'}}>
                            <span className='d-block'>{selectUser.name}</span>
                            <span className='d-block'>{selectUser.phone}</span>
                        </div>
                    </div>
                    <div>
                        <div style={{textAlign: 'right'}}>
                            <img
                                style={{marginRight: '1rem'}}
                                onClick={() => callPhone(selectUser.phone)}
                                src={phoneCallIcon} alt={'phone_call'} width={45}/>
                            <img
                                onClick={() => sendMMSWorker(selectUser.phone)}
                                src={mailIcon} alt={'phone_call'} width={45}/>
                        </div>
                    </div>
                </div>
                : <></>
            }
        </DialogSheet>
    )
}


export default UserDialog
