import React from "react";
import {Polygon} from "@react-google-maps/api";
import {useSelector} from "react-redux";


export const SelectPolygon = () => {

    const selectPolygon = useSelector(state => state.dialog.selectPolygon);

    return (
        <>
            {Object.keys(selectPolygon).length !== 0 &&
            <Polygon
                path={selectPolygon.polygon}

                options={
                    {
                        fillColor: "#B200DDB0",
                        strokeColor: 'white',
                        strokeWeight: 1,
                        fillOpacity: 0.7
                    }
                }/>
            }
        </>
    )
}
