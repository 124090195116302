import React, {useEffect, useRef, useState} from "react";
import {animated, useSpring} from "react-spring";
import {
    BottomSheetStyled,
    SheetBody,
    ThumbBar,
    ThumbBarWrapper,
} from "./BottomSheetStyles";
import {
    bottomSheetEvents,
    syncHeight,
    useReduceMotion,
} from "./utils";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import {getMyLocation, sharedLocationService } from "../../helpers/MobileFunction";
import {useDispatch, useSelector} from "react-redux";
import {toggleLocationUpdate} from "../dialog/action";

//  TODO - account for resizing the window
const DRAWER_HEIGHT = window.innerHeight * 0.7;
const INITIAL_DRAWER_DISTANCE_FROM_TOP = 300;
const DRAWER_SNAP_MARGIN = 10;
const COLLAPSED_HEIGHT = 75;
const BOTTOM_SHEET_HEIGHT = 120;
// resize listener
window.addEventListener("resize", syncHeight);
syncHeight();


export const CustomBottomSheet = ({
                                      handleSetMyLocationCenter,
                                      setFocusMap,
                                      children,
                                      initialDrawerDistanceTop = INITIAL_DRAWER_DISTANCE_FROM_TOP,
                                      isDebugMode,
                                      isOpen,
                                      onClose,
                                      onStatusChange,
                                      subtitle,
                                      title,
                                  }) => {
    // STATE
    const scrollRef = useRef(null);
    const [bottom, setBottom] = React.useState(-DRAWER_HEIGHT);
    const [dragWay, setDragWay] = useState(0);
    const [draggingPosition, setDraggingPosition] = React.useState(null);
    const [debugLog, setDebugLog] = React.useState("");
    const dispatch = useDispatch()
    const handleSharedLocation = (isBool) => {
        dispatch(toggleLocationUpdate(isBool))
        sharedLocationService(isBool)
    }
    const offToggle = () => {
        dispatch(toggleLocationUpdate(false))
    }

    const {
        showUser,
        showWork,
        showPolygon,
        showFilterDialog,
        downBottomSheet,
        toggleLocation
    } = useSelector(state => state.dialog);
    const project = useSelector(state => state.workMode.project);
    const prefersReducedMotion = useReduceMotion();
    const styles = useSpring({
        bottom,
        immediate: prefersReducedMotion,
        config: {friction: 20},
    });

    useEffect(() => {
        window.addEventListener('errSharedLocationListener', offToggle);

        return () => {
            window.removeEventListener('errSharedLocationListener', offToggle);
        }
    }, [])



    useEffect(() => {
        if (showWork ||
            showUser ||
            showFilterDialog ||
            showPolygon) {

            if (isOpen) {
                setBottom(-DRAWER_HEIGHT + BOTTOM_SHEET_HEIGHT);
            }
        }
    }, [
        showWork,
        showUser,
        showFilterDialog,
        showPolygon
    ])

    useEffect(() => {
        if (downBottomSheet) {
            setBottom(-DRAWER_HEIGHT + BOTTOM_SHEET_HEIGHT);
        }
    }, [downBottomSheet])

    const handleMyLocation = () => {
        getMyLocation()
        handleSetMyLocationCenter()
    }

    // HANDLERS
    const handlePointerDown = (e) => {
        // @ts-ignore
        // e.preventDefault()

        const event = (e?.touches != null ? e.touches[0] : e);
        setDragWay(event.clientY);
        // handle safari body scroll bug
        document.documentElement.classList.add("is-locked");
        const newDraggingPosition =
            (e.currentTarget.parentElement?.getBoundingClientRect().bottom ?? 0) -
            event.clientY;

        setDraggingPosition(newDraggingPosition);
    };

    const handlePointerMove = React.useCallback(
        (e) => {
            // e.preventDefault()
            // @ts-ignore
            const event = e?.touches != null ? e?.touches[0] : e;

            if (draggingPosition != null) {
                const newBottom = window.innerHeight - event.clientY - draggingPosition;

                if (newBottom !== bottom) {
                    if (newBottom < 0) {
                        if (bottom > -DRAWER_HEIGHT) {
                            setBottom(newBottom);
                        } else {
                            setBottom(-DRAWER_HEIGHT + BOTTOM_SHEET_HEIGHT)
                        }
                    }
                }
            }
        },
        [bottom, draggingPosition]
    );

    const handleScrollRepositioning = () => {
        if (scrollRef && scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    };

    const handleStatusChange = React.useCallback(
        (status) => {
            const newStatus = bottomSheetEvents[status];
            const newDebugLog =
                debugLog !== "" ? `${debugLog}, ${newStatus}` : newStatus;
            setDebugLog(newDebugLog);
            onStatusChange && onStatusChange(newStatus);
        },
        [debugLog, onStatusChange]
    );

    // LISTENERS

    // toggling the bottom sheet
    useEffect(() => {
        if (isOpen) {
            handleStatusChange("expanded");
            handleScrollRepositioning();
            setBottom(0);
        } else {
            handleStatusChange("dismissed");
            setBottom(-DRAWER_HEIGHT - 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    // dragging
    useEffect(() => {
        const handlePointerUp = (e) => {
            // e.preventDefault()
            document.documentElement.classList.remove("is-locked");

            if (draggingPosition == null) {
                return;
            }

            const endWay = e.changedTouches[0].clientY;

            if (bottom > -DRAWER_SNAP_MARGIN) {
                return;

            } else if (bottom < (-DRAWER_HEIGHT + BOTTOM_SHEET_HEIGHT) / 2) {
                //위로 드래그
                if (dragWay < endWay + 5) {
                    setBottom(-DRAWER_HEIGHT + BOTTOM_SHEET_HEIGHT);
                } else {
                    handleStatusChange("dismissed");
                    setBottom(0);
                }

            } else if (bottom > (-DRAWER_HEIGHT + BOTTOM_SHEET_HEIGHT) / 2) {
                //아래로 드래그

                if (dragWay > endWay - 5) {
                    setBottom(0);
                } else {
                    setBottom(-DRAWER_HEIGHT + BOTTOM_SHEET_HEIGHT);
                }
            } else if (
                bottom <
                COLLAPSED_HEIGHT - DRAWER_HEIGHT + DRAWER_SNAP_MARGIN
            ) {
                handleStatusChange("collapsed");
                setBottom(-DRAWER_HEIGHT + BOTTOM_SHEET_HEIGHT);
            } else {
                setBottom(-DRAWER_HEIGHT + BOTTOM_SHEET_HEIGHT);
            }
            setDraggingPosition(null);
        };

        document.addEventListener("touchend", handlePointerUp);
        document.addEventListener("touchmove", handlePointerMove);
        document.addEventListener("mouseup", handlePointerUp);
        document.addEventListener("mousemove", handlePointerMove);
        return () => {
            document.removeEventListener("touchend", handlePointerUp);
            document.removeEventListener("touchmove", handlePointerMove);
            document.removeEventListener("mouseup", handlePointerUp);
            document.removeEventListener("mousemove", handlePointerMove);
        };
    }, [
        bottom,
        debugLog,
        draggingPosition,
        handlePointerMove,
        handleStatusChange,
        onClose,
    ]);

    return (
        <>
            <animated.div
                style={{
                    // maxWidth: `${maxWidth}px`,
                    zIndex: 15,
                    left: "50%",
                    transform: "translateX(-50%)",
                    position: "fixed",
                    width: "100%",
                    height: DRAWER_HEIGHT,
                    transition: "height 200ms",
                    ...styles,
                }}
                onClick={() => setFocusMap(prevState => !prevState)}
            >
                {Object.keys(project).length !== 0 &&
                <div className={'my_share_location'}>
                    <button
                        onClick={() => handleSharedLocation(!toggleLocation)}
                        className={toggleLocation ? 'd-block share_location_btn active' : 'd-block share_location_btn disable'}>
                        <span>
                            {toggleLocation ? '위치공유 정지' : '위치공유 시작'}
                        </span>
                    </button>
                </div>
                }
                <div className='my_location_btn_wrapper'>
                    <button
                        onClick={handleMyLocation}
                        id={'my_location_btn'}>
                        <GpsFixedIcon style={{fontSize: '1.5rem'}}/>
                    </button>
                </div>
                <BottomSheetStyled
                    aria-modal="true"
                    role="dialog"
                    aria-labelledby="BottomSheet-title"
                >

                    <div onMouseDown={handlePointerDown} onTouchStart={handlePointerDown}>
                        <ThumbBarWrapper
                            dragging={draggingPosition != null}
                            onDragStart={(e) => {
                                e.preventDefault();
                                // get rid of the ghost drag image in the view
                                e.dataTransfer.setDragImage(new Image(), 0, 0);
                            }}
                            draggable={true}
                        >
                            <ThumbBar/>
                        </ThumbBarWrapper>
                    </div>
                    <SheetBody
                        ref={scrollRef}
                        tabIndex={0}
                        bodyHeight={
                            DRAWER_HEIGHT - bottom
                        }
                    >
                        {children}
                    </SheetBody>
                </BottomSheetStyled>
            </animated.div>
        </>
    );
};
