import * as types from './actionTypes';
import {SHOW_FILTER_DIALOG, SHOW_POLYGON_DIALOG} from "./actionTypes";

let initialState = {
    showSnackBar: false,
    snackBarMsg: '',
}


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case types.SHOW_SNACKBAR: {
            state.showSnackBar = action.open;
            state.snackBarMsg = action.message;

            return {
                ...state
            }
        }
        default:
            return state;
    }

}
