import React, {useEffect, useState} from "react";
import {Col, Modal, ModalBody, Row} from "reactstrap";
import {writeBatch, doc, Timestamp, collection, addDoc} from "firebase/firestore";
import {firestore} from "../../firebase";
import {useDispatch} from "react-redux";
import {showSnackBarDialog} from "../snackBar/action";
import dayjs from 'dayjs';
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import {sendAgreePwd} from "../workMode/action";
import CircularProgress from "@mui/material/CircularProgress";
import closeIcon from "../../assets/icon/close.svg";

dayjs.extend(isSameOrBefore)
const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const UserManageModal = ({toggle, modal, user, changeExpireDate}) => {
    const dispatch = useDispatch();
    const [month, setMonth] = useState(1);
    const [cost, setCost] = useState('');
    const [loader, setLoader] = useState(false);

    const handleExtentionUser = async () => {
        try {
            if (cost === '') {
                dispatch(showSnackBarDialog(true, '입금 금액을 입력하여 주세요.'));
                return
            }

            setLoader(true);
            const batch = writeBatch(firestore);

            const userRef = doc(firestore, "users", user.id);
            const docRef = await addDoc(collection(firestore, `users/${user.id}/bill`), {
                cost: parseInt(cost),
                created: Timestamp.now(),
                month: parseInt(month)
            });

            if (user.expireDate) {
                if (!dayjs().isSameOrBefore(
                    dayjs(user.expireDate.toDate()), "date")) {
                    //전날

                    const newExpireDate = dayjs().add(month, 'month').subtract(1, 'day');
                    batch.update(userRef, {
                        expireDate: Timestamp.fromDate(new Date(newExpireDate.get('year'), newExpireDate.get('month'), newExpireDate.get('date'), 23, 59, 59))
                    });
                    changeExpireDate(user.id, Timestamp.fromDate(new Date(newExpireDate.get('year'), newExpireDate.get('month'), newExpireDate.get('date'))))
                } else {
                    //현재날짜
                    let newExpireDate = dayjs(user.expireDate.toDate()).add(month, 'month').subtract(1, 'day');
                    batch.update(userRef, {
                        expireDate: Timestamp.fromDate(new Date(newExpireDate.get('year'), newExpireDate.get('month'), newExpireDate.get('date'), 23, 59, 59))
                    });
                    changeExpireDate(user.id, Timestamp.fromDate(new Date(newExpireDate.get('year'), newExpireDate.get('month'), newExpireDate.get('date'))))
                }


            } else {

                const newExpireDate = dayjs().add(1, 'month').subtract(1, 'day');

                batch.update(userRef, {
                    expireDate: Timestamp.fromDate(new Date(newExpireDate.get('year'), newExpireDate.get('month'), newExpireDate.get('date'), 23, 59, 59))
                });
                changeExpireDate(user.id, Timestamp.fromDate(new Date(newExpireDate.get('year'), newExpireDate.get('month'), newExpireDate.get('date'))))
                const post = {
                    data: {
                        userId: user.id,
                        phone: user.phone
                    }
                }
                await sendAgreePwd(post)
            }
            await batch.commit();
            toggle({});
            setLoader(false);
            window.location.reload();
        } catch (err) {
            if (err) {
                setLoader(false);
                dispatch(showSnackBarDialog(true, '회원 이용기간 변경에 오류가 발생하였습니다.'));
            }
        }
    }


    return (
        <Modal centered isOpen={modal} toggle={() => toggle({})} className={'user_manage_modal'}>
            <ModalBody>
                <div className={'custom_modal_header'}>
                    <div className='user_info' style={{padding: '0 0 0.5rem 0'}}>
                        <span>{user.name} 회원</span>
                        <small>{user.phone}</small>
                    </div>
                    <button onClick={() => toggle({})}><img src={closeIcon} alt={'close_icon'}/></button>
                </div>
                <Row>
                    <Col xs={3}>
                        <span>기한</span>
                    </Col>
                    <Col xs={9}>
                        <select onChange={event => setMonth(event.target.value)}>
                            {months.map(num => <option key={num} value={num}>{num}개월</option>)}
                        </select>
                    </Col>
                </Row>
                <Row style={{marginTop: '0.8rem'}}>
                    <Col xs={3}>
                        <span>입금 금액</span>
                    </Col>
                    <Col xs={9}>
                        <input
                            placeholder={'입금 금액을 입력하여 주세요.'}
                            onChange={event => setCost(event.target.value)} type={'number'}/>
                    </Col>
                </Row>
                {loader ?
                    <button id={'extend_btn'} style={{maxHeight: '45px'}} disabled={loader}>
                        <CircularProgress style={{color: 'white', width: '25px', height: '25px'}}/>
                    </button>
                    :
                    <>
                        {user.expireDate ?
                            <button id={'extend_btn'} disabled={loader} onClick={handleExtentionUser}>기간 연장</button>
                            :
                            <button id={'extend_btn'} disabled={loader} onClick={handleExtentionUser}>가입 승인</button>
                        }
                    </>
                }
            </ModalBody>
        </Modal>
    )
}

