import React, {useEffect, useState} from "react";
import {collection, doc, getDocs, query, updateDoc} from "firebase/firestore";
import {firestore} from "../../firebase";
import {useDispatch} from "react-redux";
import {showSnackBarDialog} from "../snackBar/action";
import {isIterableArray} from "../../helpers/common";

const getPayment = async () => {
    const paymentQuery = query(collection(firestore, "payment"));
    const paymentData = await getDocs(paymentQuery);
    return paymentData.docs.map(document => document.data());

}


export const PriceManage = () => {
    const dispatch = useDispatch();
    const [costData, setCostData] = useState([])
    const [commonUser, setCommonUser] = useState('');
    const [droneUser, setDroneUser] = useState('');
    const [nhUser, setNhUser] = useState('');
    useEffect(() => {
        getPayment().then(payment => {
            if (isIterableArray(payment)) {
                setCostData(payment[1].type);
            }
        }).catch(err => {
            if (err) {
                dispatch(showSnackBarDialog(true, '결제 정보를 가져오는데 문제가 발생하였습니다.'))
            }
        })

    }, [])

    const handleChangePrice = async (name) => {
        try {
            const costRef = doc(firestore, "payment", "costs");
            let newCostData = costData;
            if (name === '일반') {
                newCostData[0].cost = parseInt(commonUser);
            } else if (name === '농협 관리자') {
                newCostData[2].cost = parseInt(nhUser);
            } else {
                newCostData[1].cost = parseInt(droneUser);
            }
            await updateDoc(costRef, {
                type: newCostData
            })
            setCostData([...newCostData]);
        } catch (err) {
            if (err) {
                dispatch(showSnackBarDialog(true, '요금 업데이트에 문제가 발생하였습니다.'));
            }
        }
    }


    return (
        <div className='price_manage_wrapper'>
            {costData.map((cost, index) => {
                return (
                    <section key={index} className='price_list_item'>
                        <span className={'d-block price_name'}>{cost.name} 고객 요금</span>
                        <span className='price_won'><strong>{cost.cost.toLocaleString()}</strong> 원</span>
                        <input
                            onChange={event => {
                                if (cost.name === '일반') {
                                    setCommonUser(event.target.value);
                                } else if (cost.name === '농협 관리자') {
                                    setNhUser(event.target.value);
                                } else {
                                    setDroneUser(event.target.value);
                                }
                            }}
                            placeholder={cost.cost}
                            type={'number'}/>
                        <button onClick={() => handleChangePrice(cost.name)}>요금 변경</button>
                    </section>
                )
            })}

        </div>
    )
}
