import React, {useEffect, useState} from "react";
import {Col, Modal, ModalBody, Row} from "reactstrap";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {firestore} from "../../firebase";
import {useDispatch} from "react-redux";
import {showSnackBarDialog} from "../snackBar/action";
import {isIterableArray} from "../../helpers/common";
import moment from "moment";
import closeIcon from "../../assets/icon/close.svg";
import {CircularProgress} from "@mui/material";

export const BillRecordModal = ({modal, toggle, user}) => {
    const dispatch = useDispatch();
    const [billList, setBillList] = useState([]);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        try {
            setLoader(true);
            if (user) {
                if (Object.keys(user).length !== 0) {
                    getBillData(user.id).then(result => {
                        setBillList(result);
                        setLoader(false);
                    })
                }
            }
        } catch (err) {
            if (err) {
                dispatch(showSnackBarDialog(true, '유저 결제 기록을 가져오는데 문제가 발생하였습니다.'));
                setLoader(false);
            }
        }
    }, [user])

    const getBillData = async (userId) => {
        const billQuery = query(collection(firestore, `users/${userId}/bill`), orderBy("created", "desc"));
        const billDocs = await getDocs(billQuery);
        return billDocs.docs.map(document => document.data())

    }
    return (
        <Modal centered isOpen={modal} toggle={() => toggle({})} className={'bill_record_modal'}>
            <ModalBody style={{background: '#f1f1f1'}}>
                <div className='custom_modal_header'>
                    <div>
                        <span className={'fw-bold d-block'}>{user.name}회원 결제기록</span>
                        <small style={{color: 'gray'}}>{user.phone}</small>
                    </div>
                    <button onClick={() => toggle({})}><img src={closeIcon} alt={'close_icon'}/></button>
                </div>
                {loader ?
                    <div className={'flex_center'} style={{height: '150px'}}>
                        <CircularProgress style={{width: '30px', height: '30px', color: '#3886ff'}}/>
                    </div>
                    :
                    <>
                        {isIterableArray(billList) ?
                            <div className='admin_user_bill_list'>
                                {
                                    billList.map(bill => {
                                        return (
                                            <div className='user_bill_item'>
                                                <Row>
                                                    <Col className='p-0' xs={8}>
                                                        <span className='d-block'>결제기간 : {bill.month}개월 </span>
                                                        <span
                                                            className='d-block'>결제일 : {moment.unix(bill.created?.seconds).format('YYYY-MM-DD')} </span>
                                                    </Col>
                                                    <Col className='p-0' xs={4} style={{textAlign: 'right'}}>
                                                        <span>{bill.cost.toLocaleString()} 원</span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            :
                            <div style={{textAlign: 'center', marginTop : '2rem'}}>
                                <span>결제기록이 존재하지 않습니다.</span>
                            </div>
                        }
                    </>
                }
            </ModalBody>
        </Modal>
    )

}