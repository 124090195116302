import React, {useCallback, useEffect, useRef, useState} from "react";
import {GoogleMap, Marker, DrawingManager} from '@react-google-maps/api';
import BottomSheetBase from "../bottomSheet/BottomSheetBase";
import {getClickAreaAddress, getPolygonToAddress, getUserData, setCenterLatLng, setProject} from "./action";
import ProjectBottomSheet from "../bottomSheet/ProjectBottomSheet";
import {useDispatch, useSelector} from "react-redux";
import {isIterableArray, useQuery} from "../../helpers/common";
import {geocodeByAddress, getLatLng} from 'react-google-places-autocomplete';
import {Debounce} from "./Debounce";
import {getMyLocation, isApp} from "../../helpers/MobileFunction";
import myLocationIcon from '../../assets/icon/myLocation.svg';
import {collection, getDocs, query, where} from "firebase/firestore";
import {firestore} from "../../firebase";
import {MapLoader} from "./MapLoader";
import WorkersMarker from "./WorkersMarker";
import {SelectPolygon} from "./SelectPolygon";
import WorksPolygon from "./WorksPolygon";
import {downBottomSheet, showPolygonDialog, showWorkDialog} from "../dialog/action";
import {showSnackBarDialog} from "../snackBar/action";
import dayjs from "dayjs";
import moment from "moment";
import ExpirePage from "./ExpirePage";
import WorkAssignmentModal from "./workAssignment/WorkAssignmentModal";

const WorkMode = () => {
    let queryString = useQuery();
    const dispatch = useDispatch()
    const [step, setStep] = useState(0);
    const isActiveWorkAddMode = useSelector(state => state.firestore.isActiveWorkAddMode);
    const centerLatLng = useSelector(state => state.workMode.centerLatLng);
    const project = useSelector(state => state.workMode.project);
    const projectList = useSelector(state => state.workMode.projectList);
    const showPolygon = useSelector(state => state.dialog.showPolygon);
    const userData = useSelector(state => state.workMode.userData);
    const [loader, setLoader] = useState(false);
    const [zoom, setZoom] = useState(17);
    const [myLocation, setMyLocation] = useState({})
    const [focusMap, setFocusMap] = useState(false);
    const [focusSearch, setFocusSearch] = useState(false);
    const [isExpireUser, setIsExpireUser] = useState(false);
    const mapRef = useRef(null);
    const [isAppStatus, setIsAppStatus] = useState(true);
    const [findWorkLoader, setFindWorkLoader] = useState(false);
    const tileCacheRef = useRef({});

    const onMapLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        mapRef.current = map;


        // Custom tile overlay for satellite images
        map.overlayMapTypes.insertAt(
            0,
            new window.google.maps.ImageMapType({
                getTileUrl: (tile, zoom) => {
                    const key = `${zoom}_${tile.x}_${tile.y}`;
                    const url = `https://mt1.google.com/vt/lyrs=s&x=${tile.x}&y=${tile.y}&z=${zoom}`;

                    // Cache the tile
                    if (!tileCacheRef.current[key]) {
                        tileCacheRef.current[key] = url;
                    }

                    const img = new Image();
                    img.src = url;
                    img.onload = () => {
                        tileCacheRef.current[key] = url;
                    };
                    img.onerror = () => {
                        if (tileCacheRef.current[key]) {
                            img.src = tileCacheRef.current[key];
                        }
                    };

                    return img.src;
                },
                tileSize: new window.google.maps.Size(256, 256),
                maxZoom: 20,
                minZoom: 0,
                name: "Satellite Tiles",
            })
        );
    }, []);



    const handleSetMyLocationCenter = () => {
        if (Object.keys(myLocation).length !== 0) {
            dispatch(setCenterLatLng(myLocation));
            if (!mapRef.current) return;
            mapRef.current.setCenter(myLocation);
            mapRef.current.setZoom(16);
        }
    }


    useEffect(() => {

        const userPhoneNum = queryString.get('user');


        const getMyLocationListener = async (event) => {
            const locationData = event;

            if (locationData.detail.data) {
                if (Object.keys(locationData.detail.data).length !== 0) {
                    const coordinate = {lat: locationData.detail.data.latitude, lng: locationData.detail.data.longitude}
                    dispatch(setCenterLatLng(coordinate));
                    setMyLocation(coordinate);
                    setZoom(16);
                    if (!mapRef.current) return;
                    mapRef.current.setCenter(coordinate);
                    mapRef.current.setZoom(16);
                }
            }

        }


        const updateLocationListener = (event) => {
            const locationData = event;
            if (locationData.detail.data) {
                if (Object.keys(locationData.detail.data).length !== 0) {
                    const coordinate = {lat: locationData.detail.data.latitude, lng: locationData.detail.data.longitude}
                    setMyLocation(coordinate)
                }
            }
        }

        const isAppListener = async (event) => {
            if (event.detail.key) {
                if (event.detail.key === 'success') {
                    await isAppEvent();
                }
            }

        }

        const isAppEvent = async () => {
            const q = query(collection(firestore, "users"), where("phone", "==", userPhoneNum));

            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                const newUser = {id: doc.id, ...doc.data()};
                dispatch(getUserData(newUser));

            });
            setIsAppStatus(true);
            getMyLocation();
            handleSetMyLocationCenter()

        }

        window.addEventListener('isAppListener', isAppListener);
        window.addEventListener('getMyLocationListener', getMyLocationListener);
        window.addEventListener('updateMyLocationListener', updateLocationListener);
        window.addEventListener('firstSetMyLocationListener', getMyLocationListener);


       isApp().then(async result => {
            if (result) {
                await isAppEvent();
            }
        }).catch(async err => {
            setIsAppStatus(false);
        })


        return () => {
            window.removeEventListener('isAppListener', isAppListener)
            window.removeEventListener('getMyLocationListener', getMyLocationListener);
            window.removeEventListener('updateMyLocationListener', updateLocationListener);
            window.addEventListener('firstSetMyLocationListener', getMyLocationListener);
        };

    }, [])

    useEffect(() => {

        const goWorkListener = (event) => {
            try {

                if (event.detail.data) {
                    setFindWorkLoader(true);
                    const eventProjectId = event.detail.data?.projectId;
                    const projectData = projectList.filter(p => p.id === eventProjectId);
                    let workData = {}
                    if (event.detail.data.work) {
                        if (Object.keys(event.detail.data.work).length !== 0) {
                            workData = event.detail.data.work;
                        }
                    }
                    if (Object.keys(project).length !== 0) {
                        if (isIterableArray(projectData)) {
                            if (project.id !== projectData[0].id) {
                                dispatch(setProject(projectData[0]));
                            }
                            if (Object.keys(workData).length !== 0) {
                                const coordinate = {lat: workData.latLng.latitude, lng: workData.latLng.longitude}
                                setTimeout(() => {
                                    dispatch(setCenterLatLng(coordinate));
                                    dispatch(showWorkDialog(workData));
                                    dispatch(downBottomSheet(true));
                                    setFindWorkLoader(false);
                                }, 1000)
                            } else {
                                setFindWorkLoader(false);
                            }
                        } else {
                            setFindWorkLoader(false);
                        }
                    } else {
                        if (isIterableArray(projectData)) {
                            dispatch(setProject(projectData[0]));
                            if (Object.keys(workData).length !== 0) {
                                const coordinate = {lat: workData.latLng.latitude, lng: workData.latLng.longitude}
                                setTimeout(() => {
                                    dispatch(setCenterLatLng(coordinate));
                                    dispatch(showWorkDialog(workData));
                                    dispatch(downBottomSheet(true));
                                    setFindWorkLoader(false);
                                }, 1000)
                            } else {
                                setFindWorkLoader(false);
                            }
                        } else {
                            setFindWorkLoader(false);
                        }
                    }
                }
            } catch (err) {
                setFindWorkLoader(false);
            }
        }


        window.addEventListener('goWorkListener', goWorkListener);
        return () => {
            window.removeEventListener('goWorkListener', goWorkListener);
        }
    }, [project, projectList])

    //
    // useEffect(() => {
    //     if (!mapRef.current) return;
    //     mapRef.current.setZoom(18);
    // }, [zoom])

    useEffect(() => {
        if (!mapRef.current) return;
        if (showPolygon || Object.keys(project).length !== 0) {
            mapRef.current.setZoom(18);
        }

    }, [showPolygon])

    // useEffect(() => {
    //     if (!mapRef.current) return;
    //     if (showPolygon || Object.keys(project).length !== 0) {
    //         mapRef.current.setCenter(centerLatLng);
    //     }
    // }, [centerLatLng])

    useEffect(() => {
        if (Object.keys(userData).length !== 0) {
            const userExpireDate = moment.unix(userData?.expireDate?.seconds).format('YYYY-MM-DD HH:mm:ss');
            const isExpire = dayjs().isAfter(userExpireDate);
            setIsExpireUser(isExpire)
        }
    }, [userData])

    const handleSearchLocation = (address) => {
        return new Promise(resolve => {
            geocodeByAddress(address)
                .then(results => {
                    return getLatLng(results[0])
                })
                .then(({lat, lng}) => {
                        getPolygonToAddress({
                            data: {
                                address: address,
                                latLng: {latitude: lat, longitude: lng}
                            }
                        }).then(result => {
                            if (result) {
                                const locationLatLng = result.data.data.latLng;
                                let newPolygon = []
                                if (isIterableArray(result.data.data.polygon)) {
                                    newPolygon = result.data.data.polygon.map(pl => ({lat: pl[1], lng: pl[0]}))
                                }

                                const polygonData = {
                                    name: result.data.data.address,
                                    latLng: locationLatLng,
                                    polygon: newPolygon
                                }
                                dispatch(showPolygonDialog(polygonData));
                                dispatch(setCenterLatLng({
                                    lat: parseFloat(locationLatLng.latitude),
                                    lng: parseFloat(locationLatLng.longitude)
                                }));
                                resolve(true);
                            }
                        }).catch(err => {
                            if (err.response) {
                                if (err.response.status === 404) {
                                    dispatch(showSnackBarDialog(true, '등록이 불가능한 주소 입니다.'));
                                }
                            }
                        })
                    }
                ).catch(err => {
                if (err === 'ZERO_RESULTS' || err === 'INVALID_REQUEST') {
                    getPolygonToAddress({data: {address: address, latLng: null}}).then(result => {
                        if (result) {
                            const locationLatLng = result.data.data.latLng;
                            let newPolygon = []
                            if (isIterableArray(result.data.data.polygon)) {
                                newPolygon = result.data.data.polygon.map(pl => ({lat: pl[1], lng: pl[0]}))
                            }

                            const polygonData = {
                                name: result.data.data.address,
                                latLng: locationLatLng,
                                polygon: newPolygon
                            }
                            dispatch(showPolygonDialog(polygonData));
                            dispatch(setCenterLatLng({
                                lat: parseFloat(locationLatLng.latitude),
                                lng: parseFloat(locationLatLng.longitude)
                            }));
                            resolve(true);
                        }
                    }).catch(err => {
                        dispatch(showSnackBarDialog(true, '등록이 불가능한 주소 입니다.'));
                    })
                } else {
                    dispatch(showSnackBarDialog(true, '등록이 불가능한 주소 입니다.'));
                }

            })
        })
    }

    const handleClickPolygon = useCallback(async (event) => {
        setFocusMap(prevState => !prevState);
        if (isActiveWorkAddMode && !showPolygon) {
            getClickAreaAddress(event.latLng.lat(), event.latLng.lng()).then(result => {
                if (isIterableArray(result.data.documents)) {
                    handleSearchLocation(result.data.documents[0].address?.address_name);
                }
            }).catch(err => {
                dispatch(showSnackBarDialog(true, '등록이 불가능한 주소 입니다.'));
            })
        }
    }, [isActiveWorkAddMode])

    function handleCenter() {
        if (!mapRef.current) return;
        const newPos = mapRef.current.getCenter().toJSON();
        if (newPos.lat === centerLatLng.lat && newPos.lng === centerLatLng.lng) return
        mapRef.current.setCenter(newPos);
        dispatch(setCenterLatLng(newPos));
    }


    return (
        <div className='work_mode_main_wrapper'>
            <WorkAssignmentModal myLocation={myLocation}/>
            {isExpireUser && <ExpirePage/>}
            {findWorkLoader && <MapLoader/>}
            {isAppStatus ?
                <GoogleMap
                    onClick={event => handleClickPolygon(event)}
                    onLoad={onMapLoad}
                    onDragEnd={handleCenter}
                    options={
                        {
                            center: centerLatLng,
                            minZoom: 5,
                            maxZoom: 20,
                            mapTypeId: 'satellite',
                            mapTypeControl: false,
                            fullscreenControl: false,
                            streetViewControl: false,
                            zoomControl: false,
                        }
                    }
                    mapContainerStyle={{
                        width: '100%',
                        height: '100vh'
                    }}
                >


                    {Object.keys(myLocation).length !== 0 &&
                        <Marker
                            icon={{
                                url: myLocationIcon,
                            }}
                            position={{lat: myLocation.lat, lng: myLocation.lng}}/>
                    }

                    <SelectPolygon/>
                    <WorkersMarker/>

                    <WorksPolygon/>

                    <Debounce
                        focusSearch={focusSearch}
                        focusMap={focusMap}
                        handleSearchLocation={handleSearchLocation}/>

                    <BottomSheetBase
                        handleSetMyLocationCenter={handleSetMyLocationCenter}
                        setFocusMap={setFocusMap}
                        open={step === 0}/>
                    <ProjectBottomSheet
                        handleSetMyLocationCenter={handleSetMyLocationCenter}
                        setFocusSearch={setFocusSearch}
                        step={step}
                        setFocusMap={setFocusMap}
                        loader={loader}
                        open={step === 1}
                        setStep={setStep}/>

                </GoogleMap>
                :
                <MapLoader/>
            }
        </div>
    )
}


export default React.memo(WorkMode)
