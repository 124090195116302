const os = /Android/i.test(navigator.userAgent) ? 'android' : 'ios'
const is_iOS = (/iPad|iPhone|iPod/.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

const MESSAGE_MENT = '';
const MESSAGE_WORKER_MENT = ' '


export const goWorkDetail = (projectId) => {
    if (os === 'android') {
        window.BlueSky.goWorkDetail(projectId)
    } else {
        if (is_iOS) {
            const sendData = {type: 'goWorkDetail', message: projectId};
            window.webkit.messageHandlers.goWorkDetail.postMessage(sendData);
        }
    }
}

export const isAdminApp = async () => {
    if (os === 'android') {
        return await window.BlueSkyAdmin.isApp();
    } else {
        return false;
    }
}

export const isApp = async () => {
    if (os === 'android') {
        return await window.BlueSky.isApp();
    } else {
        if (is_iOS) {
            const sendData = {type: 'isApp', message: "isBlueSkyApp"};
            window.webkit.messageHandlers.isApp.postMessage(sendData);
        }
    }
}

export const sharedLocationService = (service) => {
    if (os === 'android') {
        window.BlueSky.sharedLocationService(service);
    } else {
        if (is_iOS) {
            const sendData = {type: 'sharedLocationService', message: service};
            window.webkit.messageHandlers.sharedLocationService.postMessage(sendData);
        }
    }

}

export const getMyLocation = () => {
    if (os === 'android') {
        window.BlueSky.getMyLocation();
    } else {
        if (is_iOS) {
            const sendData = {type: 'getMyLocation', message: "getMyLocation"};
            window.webkit.messageHandlers.getMyLocation.postMessage(sendData);
        }
    }
}

export const showCreateWorkDialog = (projectId, latLng, address, worker, workerId, isAdmin) => {
    if (os === 'android') {
        if (isAdmin) {
            window.BlueSky.showCreateWorkDialog(projectId, JSON.stringify(latLng), address, null, null, isAdmin);
        } else {
            window.BlueSky.showCreateWorkDialog(projectId, JSON.stringify(latLng), address, worker, workerId, isAdmin);
        }

    } else {
        if (is_iOS) {
            const sendData = {
                type: 'showCreateWorkDialog',
                message: JSON.stringify({
                    projectId: projectId,
                    address: address,
                    latLng: latLng,
                    worker: !isAdmin ? worker : "",
                    workerId: !isAdmin ? workerId : "",
                    isAdmin: isAdmin
                })
            };

            window.webkit.messageHandlers.showCreateWorkDialog.postMessage(sendData);
        }
    }
}


export const showStatusDialog = (workId, worker, workData, projectId) => {
    if (os === 'android') {
        window.BlueSky.showStatusDialog(workId, JSON.stringify({data: worker}));
    } else {
        if (is_iOS) {
            const sendData = {type: 'showStatusDialog', message: JSON.stringify({workData, worker, projectId})};
            window.webkit.messageHandlers.showStatusDialog.postMessage(sendData);
        }
    }
}


export const showRecordDialog = (workId) => {
    if (os === 'android') {
        window.BlueSky.showRecordDialog(workId);
    } else {
        if (is_iOS) {
            const sendData = {type: 'showRecordDialog', message: workId};
            window.webkit.messageHandlers.showRecordDialog.postMessage(sendData);
        }
    }
}

export const callPhone = (phone) => {
    if (os === 'android') {
        window.BlueSky.callPhone(phone);
    } else {
        if (is_iOS) {
            const sendData = {type: 'callPhone', message: phone};
            window.webkit.messageHandlers.callPhone.postMessage(sendData);
        }
    }
}

export const sendMMS = (phone) => {
    if (os === 'android') {
        window.BlueSky.sendMMS(phone, MESSAGE_MENT);
    } else {
        if (is_iOS) {
            const sendData = {type: 'sendMMS', message: phone};
            window.webkit.messageHandlers.sendMMS.postMessage(sendData);
        }
    }
}

export const sendMMSWorker = (phone) => {
    if (os === 'android') {
        window.BlueSky.sendMMS(phone, MESSAGE_WORKER_MENT);
    } else {
        if (is_iOS) {
            const sendData = {type: 'sendMMS', message: phone};
            window.webkit.messageHandlers.sendMMS.postMessage(sendData);
        }
    }
}

export const goNavigation = (address, latLng) => {
    if (os === 'android') {
        window.BlueSky.goNavigation(address, JSON.stringify(latLng));
    } else {
        if (is_iOS) {
            const sendData = {type: 'goNavigation', message: JSON.stringify({address: address, latLng: latLng})};
            window.webkit.messageHandlers.goNavigation.postMessage(sendData);
        }
    }
}

export const downloadExcelForm = () => {
    if (os === 'android') {
        window.BlueSkyExcel.downloadExcelForm();
    } else {
        if (is_iOS) {
            const sendData = {type: 'downloadExcelForm', message: "excel"};
            window.webkit.messageHandlers.downloadExcelForm.postMessage(sendData);
        }
    }
}
