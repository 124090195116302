


export const SHOW_USER_DIALOG = 'SHOW_USER_DIALOG';

export const SHOW_WORK_DIALOG = 'SHOW_WORK_DIALOG';

export const SHOW_POLYGON_DIALOG = 'SHOW_POLYGON_DIALOG';

export const SHOW_FILTER_DIALOG = 'SHOW_FILTER_DIALOG';

export const DOWN_BOTTOM_SHEET = 'DOWN_BOTTOM_SHEET';

export const TOGGLE_LOCATION = 'TOGGLE_LOCATION';

export const SHOW_ASSIGN_WORK_DIALOG = 'SHOW_ASSIGN_WORK_DIALOG';

export const Toggle_ASSIGN_WORK = 'Toggle_ASSIGN_WORK';

export const SHOW_ADMIN_FILTER_DIALOG = 'SHOW_ADMIN_FILTER_DIALOG';

export const USE_FILTERING = 'USE_FILTERING';