import * as types from './actionTypes';
import {FILTER_PROJECT_WORKS, SHOW_FILTER_DIALOG, SHOW_POLYGON_DIALOG} from "./actionTypes";

let initialState = {
    workList: [],
    filterWorkList: [],
    filterAdminWorkList: [],
    workerList: {},
    isActiveWorkAddMode: false,
}


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case types.FILTER_PROJECT_ADMIN_WORKS: {
            return {
                ...state,
                filterAdminWorkList: [...action.workList]
            }
        }

        case types.GET_PROJECT_WORKS: {

            return {
                ...state,
                workList: [...action.workList]
            }
        }

        case types.FILTER_PROJECT_WORKS: {

            return {
                ...state,
                filterWorkList: [...action.workList]
            }
        }

        case types.ACTIVE_WORK_ADD_MODE: {
            return {
                ...state,
                isActiveWorkAddMode: action.isActive
            }
        }

        case types.GET_PROJECT_WORKERS: {
            if (!action.worker) {
                return {
                    ...state
                }
            }
            if (Object.keys(action.worker).length === 0) {
                return {
                    ...state,
                    workerList: {}
                }
            }
            let newWork = {}
            newWork[action.worker.id] = action.worker
            let newWorkerList = Object.assign(state.workerList, newWork)
            return {
                ...state,
                workerList: newWorkerList
            }
        }


        default:
            return state;
    }

}
