import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {getStatusColor, getWorkStatusToKor} from "../../helpers/common";

const tableRows = ['expected', 'progress', 'complete', 'stop', 'except', 'rework']


export default function ProjectStatusTable() {
    const {workStatus} = useSelector(state => state.workMode.project);
    const [total, setTotal] = useState({area: 0, cnt: 0});

    useEffect(() => {
        if (workStatus) {
            if (Object.keys(workStatus).length !== 0) {
                const totalArea = tableRows.map(row => workStatus[row].area).reduce((acc, cur, idx) => {
                    return acc += cur;
                }, 0);
                const totalCnt = tableRows.map(row => workStatus[row].cnt).reduce((acc, cur, idx) => {
                    return acc += cur;
                }, 0);
                setTotal({area: totalArea, cnt: totalCnt})
            }
        }
    }, [workStatus])

    return (
        <TableContainer component={Paper} style={{marginTop: '1rem'}}>
            {workStatus &&
            <Table size="small" aria-label="a dense table">
                <TableHead style={{backgroundColor: '#dedede'}}>
                    <TableRow>
                        <TableCell align="center">상태</TableCell>
                        <TableCell align="center">면적</TableCell>
                        <TableCell align="center">개수</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableRows.map((row) => (
                        <TableRow
                            key={row}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell
                                className='fw-bold'
                                align="center">
                                <div className='flex_center'>
                                    <div style={{backgroundColor: getStatusColor(getWorkStatusToKor(row))}} id={'status_color_circle'}/>
                                    {getWorkStatusToKor(row)}
                                </div>
                            </TableCell>
                            <TableCell className='fw-bold' align="center">{workStatus[row].area?.toFixed(2)}평</TableCell>
                            <TableCell className='fw-bold' align="center">{workStatus[row].cnt}개</TableCell>

                        </TableRow>
                    ))}
                    <TableRow
                        sx={{'&:last-child td, &:last-child th': {border: 0}}}
                    >
                        <TableCell className='fw-bold' align="center">총</TableCell>
                        <TableCell className='fw-bold' align="center">{total.area?.toFixed(2)}평</TableCell>
                        <TableCell className='fw-bold' align="center">{total.cnt}개</TableCell>

                    </TableRow>
                </TableBody>
            </Table>
            }
        </TableContainer>
    );
}
