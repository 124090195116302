import React from "react";
import {BottomSheet} from "react-spring-bottom-sheet";


const DialogSheet = ({children, height, open, dismiss}) => {

    return (
        <BottomSheet
            style={{zIndex: 20}}
            open={open}
            onDismiss={dismiss}
            skipInitialTransition
            defaultSnap={({minHeight}) => minHeight * height}
            snapPoints={({minHeight, maxHeight}) => [minHeight, maxHeight * 0.9]}
        >
            {children}
        </BottomSheet>
    )

}


export default DialogSheet


DialogSheet.defalutProps = {
    height: 1
}
