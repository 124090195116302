import {combineReducers} from "redux";
import workMode from '../components/workMode/reducer';
import snackBar from '../components/snackBar/reducer';
import dialog from '../components/dialog/reducer';
import firestore from '../components/firestore/reducer';

const rootReducer = combineReducers({
    workMode,
    snackBar,
    dialog,
    firestore
});

export default rootReducer;
