import React from "react";
import {locationPolicy} from "./Policy";



export const PolicyThree = () => {

    return(
        <div
            style={{height: '100vh'}}
            className="p-3 overflow-scroll">{
            locationPolicy.split('\n').map(line => {
                return (<span>{line}<br/></span>)
            })
        }
        </div>
    )
}
