import React, {useState} from "react";
import DialogSheet from "../common/DialogSheet";
import {useDispatch, useSelector} from "react-redux";
import {showFilterDialogAction, showWorkDialog} from "./action";
import {collection, documentId, getDocs, onSnapshot, query, where} from "firebase/firestore";
import {firestore} from "../../firebase";
import {setCenterLatLng, setFiltering} from "../workMode/action";
import closeIcon from "../../assets/icon/close.svg";
import {setFilterWorks, getWorks} from "../firestore/action";
import {showSnackBarDialog} from "../snackBar/action";
import {isIterableArray} from "../../helpers/common";

const workStatus = ["예정", "진행", "완료", "중단", "제외", "재작업요청"]

const FilterDialog = () => {
    const dispatch = useDispatch()
    const {showFilterDialog} = useSelector(state => state.dialog);
    const {project} = useSelector(state => state.workMode);
    const {workList} = useSelector(state => state.firestore);
    const [filterStatus, setFilterStatus] = useState('');
    const [filterTxt, setFilterTxt] = useState('');
    const [filterType, setFilterType] = useState("주소");

    const handleDismissDialog = () => {
        dispatch(showFilterDialogAction(false));
    }

    const handleInitFilter = () => {
        try {
            dispatch(setFilterWorks(workList));
            setFilterStatus('');
            setFilterTxt('');
            dispatch(setFiltering(false));
            dispatch(showFilterDialogAction(false));
        } catch (err) {
            dispatch(showSnackBarDialog(true, '필터링 초기화에 실패하였습니다.'));
        }
    }

    const handleSetStatus = (status) => {
        setFilterStatus(status);
    }

    const handleWorkFilter = async () => {
        try {
            let useFilter = true;
            let filterWorkList = workList;
            if (isIterableArray(filterWorkList)) {
                if (filterStatus !== '') {
                    filterWorkList = workList.filter(work => work.workStatus === filterStatus)
                }
                if (filterType === "주소") {
                    filterWorkList = filterWorkList.filter(work => {
                        return work.address.includes(filterTxt)
                    })

                } else if (filterType === "성명") {
                    filterWorkList = filterWorkList.filter(work => work.farmerName === filterTxt)
                } else {
                    filterWorkList = filterWorkList.filter(work => work.farmerPhone === filterTxt)
                }


                if (useFilter) dispatch(setFiltering(true));
                else dispatch(setFiltering(false));
                dispatch(showFilterDialogAction(false));
                if (isIterableArray(filterWorkList)) {
                    dispatch(setFilterWorks(filterWorkList));
                    dispatch(setCenterLatLng({
                        lat: filterWorkList[0].latLng.latitude,
                        lng: filterWorkList[0].latLng.longitude
                    }));

                    if (filterWorkList.length === 1) {
                        dispatch(showWorkDialog(filterWorkList[0]));
                    }
                } else {
                    dispatch(setFilterWorks([]));
                    dispatch(showSnackBarDialog(true, '검색결과가 존재하지 않습니다.'));
                }
            } else {
                dispatch(setFilterWorks([]));
                dispatch(showSnackBarDialog(true, '검색결과가 존재하지 않습니다.'));

            }

        } catch (err) {
            dispatch(showSnackBarDialog(true, '필터된 정보를 가져오는데 실패하였습니다.'));
        }
    }

    return (
        <DialogSheet height={0.1} open={showFilterDialog} dismiss={handleDismissDialog}>
            <div
                className='justify-content-between d-flex'
                style={{textAlign: 'right', padding: '1rem 1rem 0 1rem'}}>
                <span
                    style={{fontSize: '1.4rem'}}
                    className='fw-bold'>필터</span>
                <img
                    onClick={handleDismissDialog}
                    src={closeIcon} alt={'close_icon'} width={30}/>
            </div>
            <div style={{zIndex: 20}} className='filter_dialog_wrapper'>
                <div>
                    <div className='address_input_wrapper'>
                        <span className='fw-bold d-block'>검색</span>
                        <div className='work_mode_filter_input'>
                            <select onChange={event => setFilterType(event.target.value)}>
                                <option value={'주소'}>주소</option>
                                <option value={'성명'}>성명</option>
                                <option value={'전화번호'}>전화번호</option>
                            </select>
                            <input
                                value={filterTxt}
                                placeholder={'검색'}
                                type={'text'}
                                onChange={event => setFilterTxt(event.target.value)}/>
                        </div>
                    </div>
                    <div className='workStatus_wrapper'>
                        <span className='fw-bold d-block'>작업 상태</span>
                        <div className='worStatus_items'>
                            {workStatus.map((status, index) => <div
                                key={index}
                                onClick={() => handleSetStatus(status)}
                                className={filterStatus === status ? 'workStatus_item select_status' : 'workStatus_item'}>
                                <span>{status}</span>
                            </div>)}
                        </div>
                    </div>
                </div>
                <div className='filter_dialog_btn_wrapper'>
                    <button onClick={handleInitFilter} id={'init_btn'}>초기화</button>
                    <button onClick={handleWorkFilter} id={'search_btn'}>검색</button>
                </div>

            </div>
        </DialogSheet>
    )
}


export default FilterDialog
