import React from "react";
import {setProject} from "./action";

import memberIcon from '../../assets/icon/user.svg'
import {getStatusColor, isIterableArray} from "../../helpers/common";
import {useDispatch, useSelector} from "react-redux";
import moment from 'moment';
import {showSnackBarDialog} from "../snackBar/action";

const ProjectList = () => {

    const dispatch = useDispatch()
    const {projectList} = useSelector(state => state.workMode);
    const handleSelectProject = (projectData) => {
        if (Object.keys(projectData).length !== 0) {
            if (projectData.status === "휴식") {
                dispatch(showSnackBarDialog(true, '해당 프로젝트는 현재 휴식상태 이므로 접근이 불가합니다.'));
            } else {
                dispatch(setProject(projectData))
            }
        }

    }

    return (
        <div className='project_list_wrapper'>
            <h4 className='fw-bold'>프로젝트</h4>
            <div className='project_list'>
                {isIterableArray(projectList) ?
                    <>
                        {projectList.map(project => {
                            return (
                                <div
                                    key={project.id}
                                    onClick={() => handleSelectProject(project)}
                                    className='project_item'
                                    style={{borderColor: getStatusColor(project.status)}}
                                >
                                    <div className='project_content'>
                                        <div className='project_title'>
                                            <span className='fw-bold'>{project.name}</span>
                                            <div className='flex_center'>
                                                <div style={{backgroundColor: getStatusColor(project.status)}}
                                                     id={'status_color_circle'}/>
                                                <small
                                                    style={{color: getStatusColor(project.status)}}>{project.status}</small>
                                            </div>
                                        </div>

                                        <div className='project_info'>
                                            <div>
                                                <span
                                                    id={'project_created_txt'}>{moment.unix(project.created?.seconds).format('YYYY-MM-DD HH:mm:ss')}</span>
                                            </div>
                                            <div>
                                                <img src={memberIcon} alt={'member_icon'}/>
                                                <small id='length_txt'>{project.memberIds.length}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                    :
                    <div className='project_not_exist'>
                        <span>소속된 프로젝트가 존재하지 않습니다.</span>
                        <small>하단 프로젝트 탭으로 이동하신 후<br/> 생성/가입하여 주시기 바랍니다.</small>
                    </div>
                }
            </div>

        </div>
    )
}

export default ProjectList
