
export const GET_USER = 'GET_USER';

export const SET_PROJECT = 'SET_PROJECT';



export const SET_FILTERING = 'SET_FILTERING';


export const GET_PROJECT_LIST = 'GET_PROJECT_LIST';

export const SET_CENTER_LATLNG = 'SET_CENTER_LATLNG';

export const SHOW_WORK_ASSIGN_MODAL = 'SHOW_WORK_ASSIGN_MODAL'