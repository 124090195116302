import React, {useEffect, useRef, useState} from "react";
import {utils, write, writeFile} from 'xlsx';
import {downloadExcelForm} from "../../helpers/MobileFunction";


export const ExcelDown = () => {




    return (
        <button className='down_excel_form_btn' onClick={downloadExcelForm}>양식 다운로드</button>
    )
}
