import React from "react";
import {privacyPolicy} from "./Policy";


export const PolicyOne = () => {

    return (
        <div
            style={{height: '100vh'}}
            className="p-3 overflow-scroll">{
            privacyPolicy.split('\n').map(line => {
                return (<span>{line}<br/></span>)
            })
        }
        </div>
    )
}
