import React, {useEffect, useState} from "react";
import {colorSetList, isIterableArray, isProjectAdmin} from "../../../helpers/common";
import {useSelector} from "react-redux";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const AssignMemberList = ({workers = []}) => {
    const project = useSelector(state => state.workMode.project);
    const userData = useSelector(state => state.workMode.userData);

    const [collapse, setCollapse] = useState(false)

    const handleClickCollapse = () => {
        setCollapse(prevState => !prevState)
    }

    return (
        <div className={collapse ? 'collapse_side_bar assign_member_list_main' : 'assign_member_list_main'}>
            <ul>
                {isProjectAdmin(project, userData) &&
                    <li>
                        <div style={{backgroundColor: "red"}} className={'color_ball_box'}/>
                        미정
                    </li>
                }
                {
                    workers.map((worker, index) => {
                        return (
                            <li key={index}>
                                <div style={{backgroundColor: colorSetList[index]}} className={'color_ball_box'}/>
                                {worker.name}
                            </li>
                        )
                    })
                }

            </ul>
            {isProjectAdmin(project, userData) &&
                <div className={'collapse_btn_main'}>
                    <button onClick={handleClickCollapse}>
                        <ArrowBackIosIcon/>
                    </button>
                </div>
            }
        </div>
    )
}


export default AssignMemberList