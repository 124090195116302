import React, {useEffect, useState} from "react";
import DialogSheet from "../common/DialogSheet";
import {useDispatch, useSelector} from "react-redux";
import {showPolygonDialog} from "./action";
import {Col, Row} from "reactstrap";
import navigationIcon from "../../assets/icon/navigationIcon.svg";
import {isIterableArray, isProjectAdmin} from "../../helpers/common";
import {goNavigation, showCreateUserWorkDialog, showCreateWorkDialog} from "../../helpers/MobileFunction";
import {showSnackBarDialog} from "../snackBar/action";

const PolygonDialog = () => {
    const dispatch = useDispatch()
    const {showPolygon, selectPolygon} = useSelector(state => state.dialog);
    const {project, userData} = useSelector(state => state.workMode);
    const [area, setArea] = useState(0);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(showPolygon);
    }, [showPolygon])

    useEffect(() => {
        if (isIterableArray(selectPolygon.polygon)) {
            const area = window.google.maps.geometry.spherical.computeArea(selectPolygon.polygon);

            if (area) {
                const polygonArea = (area * 0.3025).toFixed(2);
                setArea(polygonArea);
            }

        }
    }, [selectPolygon])

    const handleDismissDialog = () => {
        dispatch(showPolygonDialog({}));
        dispatch(showSnackBarDialog(false, ''));
    }

    const handleOpenWorkAdd = () => {
        if (Object.keys(project).length === 0) {
            dispatch(showSnackBarDialog(true, '프로젝트를 선택한 경우에만 작업추가가 가능합니다.'));
        } else {
            const userMemberData = project.members[userData.id];
            if (userMemberData) {
                if (userMemberData.authority !== '관전자') {
                    showCreateWorkDialog(project.id, selectPolygon.latLng, selectPolygon.name?.join(' '), `${userData.name} (${userData.phone})`, userData.id, isProjectAdmin(project, userData));
                } else {
                    dispatch(showSnackBarDialog(true, '관리자 또는 작업자만 작업추가가 가능합니다.'));
                }
            } else {
                dispatch(showSnackBarDialog(true, '해당 프로젝트 팀원이 아닙니다.'));
            }
        }


    }

    return (
        <DialogSheet height={0.7} open={show} dismiss={handleDismissDialog}>
            {Object.keys(selectPolygon).length !== 0 ?
                <Row style={{zIndex: 20}} className='polygon_dialog_wrapper'>
                    <Col style={{paddingRight: 0}} xs={10}>
                        <div>
                            <h4>{selectPolygon.name.join(' ')}</h4>
                            {isIterableArray(selectPolygon.polygon) &&
                                <small><strong>면적</strong> {area}평</small>
                            }
                        </div>
                    </Col>
                    <Col className='p-0' xs={2}>
                        <div className='nav_btn_wrapper'>
                            <img
                                onClick={() => goNavigation(selectPolygon.name?.join(' '), selectPolygon.latLng)}
                                src={navigationIcon} alt={'phone_call'} width={40}/>
                            <small>길찾기</small>
                        </div>
                    </Col>
                    <Col xs={12}>
                        <button
                            onClick={handleOpenWorkAdd}
                            id={'add_work_btn'}>작업추가
                        </button>
                    </Col>
                </Row>
                :
                <></>
            }
        </DialogSheet>
    )
}


export default React.memo(PolygonDialog)
