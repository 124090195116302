import {createStore, applyMiddleware} from 'redux';
import {createLogger} from 'redux-logger';
import thunk from "redux-thunk";
import reducers from './reducers'
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';


const logger = createLogger();

// export default createStore(reducers, composeWithDevTools(applyMiddleware(thunk,  logger)));

export default createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));
