import React from "react";
import expireIcon from "../../assets/icon/expire-icon.svg";

const ExpirePage = () => {

    return (
        <div className={'expire_page_main'}>
            <img src={expireIcon} alt={'expire-icon'}/>
            <div className={'text-center'} style={{marginTop: '1rem'}}>
                <span className={'d-block'}>유효기간이 만료되었습니다.</span>
                <small className={'d-block'}>고객센터를 통해 연장신청을 해주세요.</small>
            </div>
        </div>
    )
}

export default ExpirePage