import React, {useEffect, useState} from "react";
import DialogSheet from "../common/DialogSheet";
import {useDispatch, useSelector} from "react-redux";
import {showWorkDialog} from "./action";
import {Col, Row} from "reactstrap";
import {getStatusColor, isIterableArray} from "../../helpers/common";
import phoneCallIcon from '../../assets/icon/phoneCall.svg';
import mailIcon from '../../assets/icon/mail.svg';
import navigationIcon from '../../assets/icon/navigationIcon.svg';
import {callPhone, goNavigation, sendMMS, showRecordDialog, showStatusDialog} from "../../helpers/MobileFunction";
import {showSnackBarDialog} from "../snackBar/action";

const WorkDialog = () => {
    const dispatch = useDispatch();
    const {showWork, selectWork, toggleLocation} = useSelector(state => state.dialog);
    const project = useSelector(state => state.workMode.project);
    const userData = useSelector(state => state.workMode.userData);
    const workerList = useSelector(state => state.firestore.workerList);
    const [workers, setWorkers] = useState([]);
    const handleDismissDialog = () => {
        dispatch(showWorkDialog({}));
    }

    useEffect(() => {
        window.addEventListener('closeWorkDialog', handleDismissDialog)

        return () => {
            window.removeEventListener('closeWorkDialog', handleDismissDialog)
        }
    }, [])

    const handleIsAdmin = () => {
        if (Object.keys(project).length !== 0 && Object.keys(userData).length !== 0) {
            const projectUser = project?.members[userData?.id];
            if (Object.keys(projectUser).length !== 0) {
                return projectUser.authority === "관리자"
            } else {
                return false
            }
        } else {
            return false
        }
    }

    useEffect(() => {
        if (Object.keys(workerList).length !== 0) {
            const workers = []
            const workerKeys = Object.keys(workerList)
            if (workerKeys.length !== 0) {
                workerKeys.forEach(key => {
                    if (Object.keys(project).length !== 0) {
                        if (isIterableArray(project.memberIds)) {
                            if (!project.memberIds.includes(key)) {
                                return;
                            }
                        }
                    }
                    const workerData = workerList[key];
                    if (Object.keys(workerData).length !== 0) {
                        workers.push({id: workerData.id, name: workerData.name, phone: workerData.phone})
                    }
                })
            }
            setWorkers(workers);
            console.log("//select", selectWork)

        }
    }, [selectWork, workerList, project])

    return (
        <DialogSheet height={0.7} open={showWork} dismiss={handleDismissDialog}>
            {Object.keys(selectWork).length !== 0 ?
                <div style={{zIndex: 9999}} className='work_dialog_wrapper'>
                    <div>
                        <h4>{selectWork.address}</h4>
                    </div>
                    <div className='work_action_wrapper'>
                        <div className='work_info'>
                            <div className='work_status_chip'>
                            <span
                                style={{color: getStatusColor(selectWork.workStatus)}}
                                className='fw-bold'>{selectWork.workStatus}</span>
                            </div>
                        </div>
                        <div className='phone_action_btn'>
                            <img
                                onClick={() => callPhone(selectWork.farmerPhone)}
                                src={phoneCallIcon} alt={'phone_call'} width={40}/>
                            <img
                                onClick={() => sendMMS(selectWork.farmerPhone)}
                                src={mailIcon} alt={'phone_call'} width={40}/>
                            <img
                                onClick={() => goNavigation(selectWork.address, selectWork.latLng)}
                                src={navigationIcon} alt={'go_nav'} width={40}/>
                        </div>
                    </div>
                    <div>
                        <Row>
                            <Col xs={2}>
                                <span className='d-block fw-bold'>농주</span>
                                <span className='d-block fw-bold'>면적</span>
                                <span className='d-block fw-bold'>요청사항</span>
                            </Col>
                            <Col xs={10}>
                                <span
                                    className='d-block'>{`${selectWork.farmerName} (${selectWork.farmerPhone})`}</span>
                                <span className='d-block'>{`${selectWork.area?.toFixed(2)}평`}</span>
                                <span className='d-block'>{selectWork.request}</span>
                            </Col>
                        </Row>
                    </div>
                    <div className='work_action_btn_wrapper'>
                        <button
                            onClick={() => {
                                let newWorkId = ""
                                if (selectWork.id) {
                                    newWorkId = selectWork.id
                                } else {
                                    newWorkId = selectWork.workId
                                }
                                showRecordDialog(newWorkId)
                            }}
                            id={'work_record_btn'}>작업 기록
                        </button>
                        <button
                            onClick={() => {

                                if (!toggleLocation && !handleIsAdmin()) {
                                    dispatch(showSnackBarDialog(true, '위치공유가 켜진상태에서만 상태 변경이 가능합니다.'));
                                    return;
                                }
                                if (project.status === "진행") {
                                    let newWorkId = ""
                                    if (selectWork.id) {
                                        newWorkId = selectWork.id
                                    } else {
                                        newWorkId = selectWork.workId
                                    }
                                    showStatusDialog(newWorkId, workers, selectWork, project.id);
                                    handleDismissDialog()
                                } else {
                                    dispatch(showSnackBarDialog(true, '프로젝트에 상태가 진행에서만 상태가 변경 가능합니다.'));
                                }


                            }}
                            id='change_worStatus_btn'>상태 변경
                        </button>
                    </div>
                </div>
                :
                <></>
            }
        </DialogSheet>
    )
}


export default WorkDialog
