import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import {useDispatch, useSelector} from "react-redux";
import Slide from '@mui/material/Slide';
import {showSnackBarDialog} from "./action";

export default function AlertSnackBar() {
    const dispatch = useDispatch();
    const {showSnackBar, snackBarMsg} = useSelector(state => state.snackBar)


    function TransitionUp(props) {
        return <Slide {...props} direction="up"/>;
    }


    const handleClose = (event, reason) => {
        if (reason !== 'clickaway') {
            dispatch(showSnackBarDialog(false, ''));
        }

    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={showSnackBar}
            autoHideDuration={3000}
            TransitionComponent={TransitionUp}
            onClose={handleClose}
            message={snackBarMsg}
        />
    );
}
