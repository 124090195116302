import * as types from './actionTypes';

let initialState = {
    showUser: false,
    selectUser: {},
    selectWork: {},
    showWork: false,
    selectPolygon: {},
    showPolygon: false,
    showFilterDialog: false,
    downBottomSheet: false,
    toggleLocation: false,
    showAssignWork: false,
    selectAssignWork: {
        count: 0,
        area: 0.0
    },
    toggleShowAssignWorks: false,
    showAdminFilter: false,
    adminFilterMembers: [],
    useFilter: false
}


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case types.USE_FILTERING: {
            return {
                ...state,
                useFilter: action.toggle
            }
        }

        case types.SHOW_ADMIN_FILTER_DIALOG: {
            return {
                ...state,
                showAdminFilter: action.open,
                adminFilterMembers: action.members

            }
        }

        case types.Toggle_ASSIGN_WORK: {
            return {
                ...state,
                toggleShowAssignWorks: action.toggle
            }
        }

        case types.SHOW_ASSIGN_WORK_DIALOG: {
            return {
                ...state,
                showAssignWork: action.open,
                selectAssignWork: {
                    count: action.count,
                    area: action.area
                }
            }
        }

        case types.TOGGLE_LOCATION: {
            return {
                ...state,
                toggleLocation: action.toggle
            }
        }

        case types.DOWN_BOTTOM_SHEET: {
            state.downBottomSheet = action.down;
            return {
                ...state
            }
        }

        case types.SHOW_FILTER_DIALOG: {
            state.showFilterDialog = action.open;

            return {
                ...state
            }
        }


        case types.SHOW_USER_DIALOG: {

            if (Object.keys(action.user).length !== 0) {
                state.selectUser = action.user;
                state.showUser = true;
            } else {
                state.selectUser = {};
                state.showUser = false;
            }
            return {
                ...state
            }
        }

        case types.SHOW_POLYGON_DIALOG: {
            if (Object.keys(action.polygon).length !== 0) {
                state.selectPolygon = action.polygon;
                state.showPolygon = true;
            } else {
                state.selectPolygon = {};
                state.showPolygon = false;
            }
            return {
                ...state
            }
        }

        case types.SHOW_WORK_DIALOG: {
            if (Object.keys(action.work).length !== 0) {
                state.selectWork = action.work;
                state.showWork = true;
            } else {
                state.selectWork = {};
                state.showWork = false;
            }
            return {
                ...state
            }
        }


        default:
            return state;
    }

}
