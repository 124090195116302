import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {colorSetList, isIterableArray, isProjectAdmin} from "../../../helpers/common";
import {Marker, MarkerClusterer, Polygon} from "@react-google-maps/api";
import {showWorkDialog} from "../../dialog/action";


const AssignPolygons = ({dragable = false}) => {
    const dispatch = useDispatch();
    const filterAdminWorkList = useSelector(state => state.firestore.filterAdminWorkList);
    const project = useSelector(state => state.workMode.project);
    const workerList = useSelector(state => state.firestore.workerList);
    const [workers, setWorkers] = useState([]);
    const userData = useSelector(state => state.workMode.userData);
    const showAssignModal = useSelector(state => state.workMode.showAssignModal);

    useEffect(() => {
        if (showAssignModal) {
            if (Object.keys(workerList).length !== 0) {
                const workers = []
                const workerKeys = Object.keys(workerList)
                if (workerKeys.length !== 0) {
                    if (isProjectAdmin(project, userData)) {
                        //관리자 일경우 전부
                        workerKeys.forEach(key => {
                            if (Object.keys(project).length !== 0) {
                                if (isIterableArray(project.memberIds)) {
                                    if (!project.memberIds.includes(key)) {
                                        return;
                                    } else {
                                        const workerData = workerList[key];
                                        if (Object.keys(workerData).length !== 0) {
                                            workers.push({
                                                id: workerData.id,
                                                name: workerData.name,
                                                phone: workerData.phone
                                            })
                                        }
                                    }
                                }
                            }

                        })
                    } else {
                        //관리자 아닐경우 본인만
                        const workerData = workerList[userData.id ?? ""];
                        if (Object.keys(workerData).length !== 0) {
                            workers.push({
                                id: workerData.id,
                                name: workerData.name,
                                phone: workerData.phone
                            })
                        }
                    }
                }

                setWorkers(workers);
            }
        }
    }, [workerList, project, showAssignModal])

    return (
        <div className={'assign_polygon_main'}>
            {isIterableArray(filterAdminWorkList) &&
                filterAdminWorkList.map((work, index) => {
                    let colorIndex = -1

                    if (!isProjectAdmin(project, userData)) {
                        if (!work.workerId) {
                            return <></>
                        }
                    }

                    if (work.workerId) {
                        if (!isProjectAdmin(project, userData)) {
                            if (work.workerId !== userData.id ?? "") {
                                return <></>
                            }
                        }
                        colorIndex = workers.findIndex(user => user.id === work.workerId);
                    }
                    if (!work.polygon) {
                        return <></>
                    }
                    const workPath = work.polygon.map(userPath => ({lat: userPath.latitude, lng: userPath.longitude}))
                    return (
                        <Polygon
                            key={index}
                            onClick={() => {
                                if (!dragable) {
                                    dispatch(showWorkDialog(work))
                                }
                            }}
                            path={workPath}
                            options={
                                {
                                    fillColor: colorIndex === -1 ? "red" : colorSetList[colorIndex],
                                    strokeColor: 'white',
                                    strokeWeight: 2,
                                    fillOpacity: 0.7
                                }
                            }/>
                    )
                })

            }
            {isIterableArray(filterAdminWorkList) &&
                <MarkerClusterer
                    maxZoom={13}>
                    {(markerCluster) => (
                        filterAdminWorkList.map((work, index) =>
                            <Marker
                                key={index}
                                clusterer={markerCluster}
                                position={{lat: work.latLng.latitude, lng: work.latLng.longitude}}
                                visible={false}/>
                        )
                    )
                    }
                </MarkerClusterer>
            }
        </div>
    )
}


export default AssignPolygons