import './App.css';
import {Provider, useSelector} from 'react-redux';
import store from './store';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {RouteIf} from "./hoc/RouteIf";
import WorkMode from "./components/workMode/WorkMode";
import AlertSnackBar from "./components/snackBar/AlertSnackBar";
import {LoadScript} from '@react-google-maps/api';
import React, {useState} from "react";
import FilterDialog from "./components/dialog/FilterDialog";
import PolygonDialog from "./components/dialog/PolygonDialog";
import UserDialog from "./components/dialog/UserDialog";
import WorkDialog from "./components/dialog/WorkDialog";
import {WorkExcel} from "./components/excel/WorkExcel";
import {MapLoader} from "./components/workMode/MapLoader";
import AdminSetting from "./components/adminSetting/AdminSetting";
import {PolicyThree} from "./components/policy/PolicyThree";
import {PolicyOne} from "./components/policy/PolicyOne";
import {PolicyTwo} from "./components/policy/PolicyTwo";
import AssignWorksDialog from "./components/dialog/AssignWorksDialog";
import FilterAdminDialog from "./components/dialog/FilterAdminDialog";


function App() {
    const [mapLibrary] = useState(['places', 'geometry', 'drawing']);

    return (
        <Provider store={store}>
            <LoadScript
                loadingElement={<MapLoader/>}
                id="script-loader"
                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                libraries={mapLibrary}
                mapTypeId={'satellite'}
                language="ko"
                region="us"
            >
                <Router>
                    <Switch>
                        <RouteIf exact path="/" option={null} component={WorkMode}/>
                        <RouteIf exact path="/excel" option={null} component={WorkExcel}/>
                        <RouteIf exact path="/adminSetting" option={null} component={AdminSetting}/>
                        <RouteIf exact path="/location" option={null} component={PolicyThree}/>
                        <RouteIf exact path="/privacy" option={null} component={PolicyOne}/>
                        <RouteIf exact path="/agreement" option={null} component={PolicyTwo}/>
                    </Switch>
                </Router>
                <AlertSnackBar/>
                <FilterDialog/>
                <PolygonDialog/>
                <UserDialog/>
                <WorkDialog/>
                <AssignWorksDialog/>
                <FilterAdminDialog/>
            </LoadScript>
        </Provider>
    );
}

export default App;


