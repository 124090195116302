import * as types from './actionTypes';
import {SHOW_FILTER_DIALOG, SHOW_POLYGON_DIALOG} from "./actionTypes";

let initialState = {
    projectList: [],
    project: {},
    isFiltering: false,
    centerLatLng: {lat: 37.57570134889708, lng: 126.97699346610399},
    showAssignModal: false,
    userData: {}
}


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case types.SHOW_WORK_ASSIGN_MODAL: {
            return {
                ...state,
                showAssignModal: action.toggle
            }
        }

        case types.GET_USER: {
            return {
                ...state,
                userData: action.user
            }
        }



        case types.SET_CENTER_LATLNG: {
            return {
                ...state,
                centerLatLng: action.latLng
            }
        }


        case types.GET_PROJECT_LIST: {
            return {
                ...state,
                projectList: action.projects
            }
        }

        case types.SET_FILTERING: {
            state.isFiltering = action.isFilter
            return {
                ...state
            }
        }

        case types.SET_PROJECT: {

            return {
                ...state,
                project: action.project
            }
        }


        default:
            return state;
    }

}
