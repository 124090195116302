import styled, {css} from "styled-components";

export const BottomSheetStyled = styled.div`
  ${({theme}) => css`
    background: white;
    box-shadow: 0px 1px 8px rgb(0 0 0 / 30%);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin: auto;
    width: 100%;
    height: 100%;
  `}
`;

// export const CloseButton = styled.button`
//   ${({theme}) => css`
//     height: 40px;
//     background: ${theme.surface};
//     cursor: pointer;
//     border: none;
//     padding: 8px;
//     border-radius: 100%;
//     position: absolute;
//     right: 16px;
//
//     &:hover {
//       background: ${theme.surfaceHover};
//     }
//   `}
// `;
//
// export const DebugLog = styled.p`
//   ${({theme}) => css`
//     color: ${theme.onSurface};
//   `}
// `;

export const Header = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  padding-bottom: 16px;
`;

// export const HeaderTitle = styled.div`
//   ${({theme}) => css`
//     display: flex;
//     flex-direction: column;
//     text-align: center;
//     justify-content: center;
//
//     & h1 {
//       color: ${theme.onSurface};
//       font-size: 1.125rem;
//       line-height: 1.5;
//       margin: 0;
//     }
//
//     & h2 {
//       color: ${theme.onSurfaceSecondary};
//       font-size: 0.875rem;
//       line-height: 1;
//       font-weight: 600;
//       margin: 0;
//     }
//   `}
// `;


export const SheetBody = styled.div`
  ${({bodyHeight}) => css`
    ${"overflow-Y"}: auto;
    width: 100%;
    height: ${bodyHeight}px;
    transition: height 200ms;
  `}
`;

export const ThumbBarWrapper = styled.div`
  padding: 0.5rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  cursor: move; /* fallback if grab cursor is unsupported */
  ${(p) =>
          p.dragging
                  ? css`
                    cursor: grabbing;
                    cursor: -moz-grabbing;
                    cursor: -webkit-grabbing;
                  `
                  : css`
                    cursor: grab;
                    cursor: -moz-grab;
                    cursor: -webkit-grab;
                  `}
`;

export const ThumbBar = styled.div`
  ${({theme}) => css`
    background: #a0a0a0;
    width: 60px;
    margin: 8px auto;
    height: 0.4rem;
    border-radius: 4px;
  `}
`;
