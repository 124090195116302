import * as types from "./actionTypes";
import axios from 'axios';
import {SHOW_WORK_ASSIGN_MODAL} from "./actionTypes";


export const getProjectList = (projects) => {
    return {
        type: types.GET_PROJECT_LIST,
        projects
    }
}


export const setProject = (project) => {

    return {
        type: types.SET_PROJECT,
        project
    }
}




export const getPolygonToAddress = async (post) => {
    return await axios.post(`https://asia-northeast3-bluesky-d6001.cloudfunctions.net/polygonToAddress`, post, {
        headers: {
            "Content-Type": 'application/json',
            "Accept": "*/*"
        }
    });

}

export const sendAgreePwd = async (post) => {
    return await axios.post(`https://asia-northeast3-bluesky-d6001.cloudfunctions.net/payment2`, post, {
        headers: {
            "Content-Type": 'application/json',
            "Accept": "*/*"
        }
    });

}


export const setFiltering = (isFilter) => {
    return {
        type: types.SET_FILTERING,
        isFilter
    }
}


export const setCenterLatLng = (latLng) => {
    return {
        type: types.SET_CENTER_LATLNG,
        latLng
    }
}


export const getClickAreaAddress = async (lat, lng) => {
    return await axios.get(`https://dapi.kakao.com/v2/local/geo/coord2address?y=${lat}&x=${lng}`, {
        headers: {
            "Content-Type": 'application/json',
            "Accept": "*/*",
            "Authorization": "KakaoAK b78930a9c4b0868d4fdbd64412323db8"
        }
    });

}



export const getUserData = (user) => {
    return {
        type : types.GET_USER,
        user
    }
}

export const setShowAssignModal = (toggle) => {

    return {
        type : types.SHOW_WORK_ASSIGN_MODAL,
        toggle
    }
}
