import React, {useEffect, useState, useRef, useCallback} from "react";
import {Modal, ModalBody} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {setShowAssignModal} from "../action";
import closeIcon from "../../../assets/icon/close.svg";
import {GoogleMap, DrawingManager, Marker} from '@react-google-maps/api';
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import myLocationIcon from '../../../assets/icon/myLocation.svg';
import AssignMemberList from "./AssignMemberList";
import AssignPolygons from "./AssignPolygons";
import {isIterableArray, isProjectAdmin} from "../../../helpers/common";
import {showAssignWorkDialog, showFilterAdminDialog, showWorkDialog} from "../../dialog/action";
import AssignmentStatusModal from "./AssignmentStatusModal";

const WorkAssignmentModal = ({myLocation}) => {
    const dispatch = useDispatch();
    const showAssignModal = useSelector(state => state.workMode.showAssignModal);
    const {filterAdminWorkList} = useSelector(state => state.firestore);
    const [modal, setModal] = useState(false);
    const [modalLocation, setModalLocation] = useState({});
    const [containWorks, setContainWorks] = useState([]);
    const mapRef = useRef(null);
    const drawPolygonRef = useRef(null);
    const [dragable, setDragable] = useState(false);
    const [dialogTouch, setDialogTouch] = useState(false);
    const project = useSelector(state => state.workMode.project);
    const userData = useSelector(state => state.workMode.userData);
    const workerList = useSelector(state => state.firestore.workerList);
    const {showAssignWork, useFilter} = useSelector(state => state.dialog);
    const [workers, setWorkers] = useState([]);
    const tileCacheRef = useRef({});

    const onMapLoad = useCallback((map) => {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        mapRef.current = map;


        // Custom tile overlay for satellite images
        map.overlayMapTypes.insertAt(
            0,
            new window.google.maps.ImageMapType({
                getTileUrl: (tile, zoom) => {
                    const key = `${zoom}_${tile.x}_${tile.y}`;
                    const url = `https://mt1.google.com/vt/lyrs=s&x=${tile.x}&y=${tile.y}&z=${zoom}`;

                    // Cache the tile
                    if (!tileCacheRef.current[key]) {
                        tileCacheRef.current[key] = url;
                    }

                    const img = new Image();
                    img.src = url;
                    img.onload = () => {
                        tileCacheRef.current[key] = url;
                    };
                    img.onerror = () => {
                        if (tileCacheRef.current[key]) {
                            img.src = tileCacheRef.current[key];
                        }
                    };

                    return img.src;
                },
                tileSize: new window.google.maps.Size(256, 256),
                maxZoom: 20,
                minZoom: 0,
                name: "Satellite Tiles",
            })
        );
    }, []);


    useEffect(() => {
        if (showAssignModal) {
            if (Object.keys(workerList).length !== 0) {
                const workers = []
                const workerKeys = Object.keys(workerList)
                if (workerKeys.length !== 0) {
                    if (isProjectAdmin(project, userData)) {
                        //관리자 일경우 전부
                        workerKeys.forEach(key => {
                            if (Object.keys(project).length !== 0) {
                                if (isIterableArray(project.memberIds)) {
                                    if (!project.memberIds.includes(key)) {
                                        return;
                                    } else {
                                        const workerData = workerList[key];
                                        if (Object.keys(workerData).length !== 0) {
                                            workers.push({
                                                id: workerData.id,
                                                name: workerData.name,
                                                phone: workerData.phone
                                            })
                                        }
                                    }
                                }
                            }

                        })
                    } else {
                        //관리자 아닐경우 본인만
                        const workerData = workerList[userData.id ?? ""];
                        if (Object.keys(workerData).length !== 0) {
                            workers.push({
                                id: workerData.id,
                                name: workerData.name,
                                phone: workerData.phone
                            })
                        }
                    }
                }
                setWorkers(workers);
            }
        }
    }, [workerList, project,showAssignModal])

    useEffect(() => {
        if (showAssignWork) {
            setDialogTouch(true)
        } else {
            setDialogTouch(false)
        }
        if (!dragable || !showAssignWork) {

            if (drawPolygonRef.current) {
                drawPolygonRef.current.setMap(null);
                drawPolygonRef.current = null
                setDragable(false);
            }
        }
    }, [dragable, showAssignWork])


    useEffect(() => {
        if (myLocation) {
            setModalLocation(myLocation)
        }
    }, [myLocation])


    useEffect(() => {
        setModal(showAssignModal)
        if (showAssignModal) {
            setTimeout(() => {
                if (isIterableArray(filterAdminWorkList)) {
                    const latLng = {
                        lat: filterAdminWorkList[0].latLng.latitude,
                        lng: filterAdminWorkList[0].latLng.longitude
                    }
                    if (!mapRef.current) return;
                    mapRef.current.setCenter(latLng);
                    mapRef.current.setZoom(16);
                }
            }, 1000)
        }
    }, [showAssignModal])

    const toggle = () => {
        dispatch(setShowAssignModal(!showAssignModal))
    }


    const onPolygonComplete = polygon => {
        drawPolygonRef.current = polygon
        const includePolygons = []
        filterAdminWorkList.forEach(work => {
            if (isIterableArray(work.polygon)) {
                work.polygon.forEach(latLng => {
                    const coordinate = new window.google.maps.LatLng(latLng.latitude, latLng.longitude)
                    if (window.google.maps.geometry.poly.containsLocation(coordinate, polygon)) {
                        if (includePolygons.filter(iWork => iWork.id === work.id).length === 0) {
                            includePolygons.push(work);
                        }

                    }
                })
            }

        })
        setContainWorks(includePolygons);
        const totalArea = includePolygons.map(row => parseFloat(row.area)).reduce((acc, cur) => {
            return acc += cur;
        }, 0);
        dispatch(showAssignWorkDialog(true, includePolygons.length, totalArea));
    }

    useEffect(() => {
        if (isIterableArray(filterAdminWorkList)) {
            const latLng = {lat: filterAdminWorkList[0].latLng.latitude, lng: filterAdminWorkList[0].latLng.longitude}
            if (!mapRef.current) return;
            mapRef.current.setCenter(latLng);
            mapRef.current.setZoom(16);
        }
    }, [filterAdminWorkList])

    const handleMyLocation = () => {
        if (Object.keys(modalLocation).length !== 0) {
            if (!mapRef.current) return;
            mapRef.current.setCenter(modalLocation);
            mapRef.current.setZoom(16);
        }


    }

    const handleClickOpenFilterDialog = () => {
        dispatch(showFilterAdminDialog(true, workers))
    }

    return (
        <Modal centered isOpen={modal} toggle={toggle} className={'work_assign_modal'}>
            <ModalBody className={'p-0'}>
                <AssignmentStatusModal works={containWorks}
                                       members={workers}/>
                <div className={'modal_header'}>
                    <button onClick={() => toggle()}><img src={closeIcon} alt={'close_icon'}/></button>
                    <span>작업자 할당 모드</span>
                    <button
                        onClick={handleClickOpenFilterDialog}
                        className={useFilter ? 'work_assign_filter_btn active' : 'work_assign_filter_btn'}>필터
                    </button>
                </div>
                <div className={'work_assign_main'}>
                    <GoogleMap
                        onClick={() => dispatch(showWorkDialog({}))}
                        mapContainerClassName={'map_container'}
                        onLoad={onMapLoad}
                        options={
                            {
                                // center: centerLatLng,
                                minZoom: 5,
                                maxZoom: 18,
                                mapTypeId: 'satellite',
                                mapTypeControl: false,
                                fullscreenControl: false,
                                streetViewControl: false,
                                zoomControl: false,
                            }
                        }
                        mapContainerStyle={{
                            width: '100%',
                        }}
                    >
                        {!dialogTouch &&
                            <>
                                {dragable &&
                                    <DrawingManager
                                        drawingMode={'polygon'}
                                        onPolygonComplete={onPolygonComplete}
                                        options={{
                                            drawingControl: false,
                                            polygonOptions: {
                                                draggable: false,
                                                fillColor: 'red',
                                                strokeColor: 'red',
                                                zIndex: 999
                                            }
                                        }
                                        }
                                    />
                                }
                            </>
                        }
                        {Object.keys(modalLocation).length !== 0 &&
                            <Marker
                                icon={{
                                    url: myLocationIcon,
                                }}
                                position={{lat: modalLocation.lat, lng: modalLocation.lng}}/>
                        }
                        <AssignMemberList workers={workers}/>
                        <AssignPolygons dragable={dragable}/>
                        <div className='my_location_modal_btn_wrapper'>
                            <button
                                onClick={handleMyLocation}
                                id={'my_location_btn'}>
                                <GpsFixedIcon style={{fontSize: '1.5rem'}}/>
                            </button>
                        </div>
                        {isProjectAdmin(project, userData) &&
                            <div className={dragable ? 'dragable_btn_wrapper active' : 'dragable_btn_wrapper'}>
                                <button
                                    onClick={() => setDragable(prevState => !prevState)}
                                >
                                    {dragable ?
                                        '영역 지정취소'
                                        :
                                        '영역 지정하기'
                                    }

                                </button>
                            </div>
                        }
                    </GoogleMap>
                </div>
            </ModalBody>
        </Modal>
    )
}


export default WorkAssignmentModal