import React, {useState} from "react";
import DialogSheet from "../common/DialogSheet";
import {useDispatch, useSelector} from "react-redux";
import {setUseFilterling, showFilterAdminDialog} from "./action";
import closeIcon from "../../assets/icon/close.svg";
import {setFilterAdminWorks} from "../firestore/action";
import {showSnackBarDialog} from "../snackBar/action";
import {isIterableArray} from "../../helpers/common";

const workStatus = ["예정", "진행", "완료", "중단", "제외", "재작업요청"]

const FilterAdminDialog = () => {
    const dispatch = useDispatch()
    const {showAdminFilter, adminFilterMembers} = useSelector(state => state.dialog);
    const {workList} = useSelector(state => state.firestore);
    const [filterStatus, setFilterStatus] = useState('');
    const [filterMembers, setFilterMembers] = useState([])

    const handleDismissDialog = () => {
        dispatch(showFilterAdminDialog(false, []));
    }

    const handleInitFilter = () => {
        try {
            dispatch(setFilterAdminWorks(workList))
            dispatch(setUseFilterling(false))
            handleDismissDialog();
            setFilterStatus('');
            setFilterMembers([]);

        } catch (err) {
            dispatch(showSnackBarDialog(true, '필터링 초기화에 실패하였습니다.'));
        }
    }

    const handleSetStatus = (status) => {
        if (filterStatus === status){
            setFilterStatus('')
        }else {
            setFilterStatus(status);
        }

    }

    const handleSetMember = (memberId) => {
        if (filterMembers.includes(memberId)) {
            const newFilterMembers = filterMembers.filter(id => id !== memberId);
            setFilterMembers([...newFilterMembers])
        } else {
            setFilterMembers(prevState => [...prevState, memberId])
        }
    }
    const handleWorkFilter = async () => {
        try {
            let filterWorkList = workList;
            if (isIterableArray(filterWorkList)) {
                if (filterStatus !== '') {
                    filterWorkList = filterWorkList.filter(work => work.workStatus === filterStatus)
                }

                if (isIterableArray(filterMembers)) {
                    let notAssignWorkList = [];
                    let existAssignWorkList = [];
                    if (filterMembers.includes('not')) {
                        notAssignWorkList = filterWorkList.filter(work => !work.workerId)
                    }

                    existAssignWorkList = filterWorkList.filter(work => filterMembers.includes(work.workerId))
                    filterWorkList = [...notAssignWorkList, ...existAssignWorkList]
                }

                if (isIterableArray(filterWorkList)) {
                    dispatch(setUseFilterling(true))
                    dispatch(setFilterAdminWorks(filterWorkList));
                    handleDismissDialog()
                } else {
                    dispatch(setFilterAdminWorks([]));
                    dispatch(showSnackBarDialog(true, '검색결과가 존재하지 않습니다.'));
                }
            } else {
                dispatch(setFilterAdminWorks([]));
                dispatch(showSnackBarDialog(true, '검색결과가 존재하지 않습니다.'));

            }

        } catch (err) {
            dispatch(showSnackBarDialog(true, '필터된 정보를 가져오는데 실패하였습니다.'));
        }
    }

    return (
        <DialogSheet height={0.1} open={showAdminFilter} dismiss={handleDismissDialog}>
            <div
                className='justify-content-between d-flex'
                style={{textAlign: 'right', padding: '1rem 1rem 0 1rem'}}>
                <span
                    style={{fontSize: '1.4rem'}}
                    className='fw-bold'>필터</span>
                <img
                    onClick={handleDismissDialog}
                    src={closeIcon} alt={'close_icon'} width={30}/>
            </div>
            <div style={{zIndex: 20}} className='filter_dialog_wrapper'>
                <div>
                    <div className='workStatus_wrapper'>
                        <span className='fw-bold d-block'>작업 상태</span>
                        <div className='worStatus_items'>
                            {workStatus.map((status, index) => <div
                                key={index}
                                onClick={() => handleSetStatus(status)}
                                className={filterStatus === status ? 'workStatus_item select_status' : 'workStatus_item'}>
                                <span>{status}</span>
                            </div>)}
                        </div>
                    </div>
                </div>
                <div>
                    <div className='workStatus_wrapper'>
                        <span className='fw-bold d-block'>작업자</span>
                        {isIterableArray(adminFilterMembers) &&
                            <div className='worStatus_items'>
                                <div
                                    onClick={() => handleSetMember('not')}
                                    className={filterMembers.includes('not') ? 'workStatus_item select_status' : 'workStatus_item'}>
                                    <span>미정</span>
                                </div>

                                {adminFilterMembers.map((member, index) => <div
                                    key={index}
                                    onClick={() => handleSetMember(member.id)}
                                    className={filterMembers.includes(member.id) ? 'workStatus_item select_status' : 'workStatus_item'}>
                                    <span>{member.name}</span>
                                </div>)}
                            </div>
                        }
                    </div>
                </div>

                <div className='filter_dialog_btn_wrapper'>
                    <button onClick={handleInitFilter} id={'init_btn'}>초기화</button>
                    <button onClick={handleWorkFilter} id={'search_btn'}>검색</button>
                </div>

            </div>
        </DialogSheet>
    )
}


export default FilterAdminDialog
