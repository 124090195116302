import React from "react";
import {Modal, ModalBody} from "reactstrap";


export const WorkExcelModal = ({modal, toggle, excel}) => {

    return (
        <Modal centered isOpen={modal} toggle={toggle} className={'work_excel_modal'}>
            <ModalBody>
                <div className='work_excel_modal_content'>
                    <div className='work_info_wrapper'>
                        <span className={'fw-bold'}>주소</span>
                        <span> {excel.address}</span>
                    </div>
                    <div className='work_info_wrapper'>
                        <span className={'fw-bold'}>농장주 이름</span>
                        <span>{excel.farmerName}</span>
                    </div>
                    <div className='work_info_wrapper'>
                        <span className={'fw-bold'}>농장주 전화번호</span>
                        <span>{excel.farmerPhone}</span>
                    </div>
                    <div className='work_info_wrapper'>
                        <span className={'fw-bold'}>요청사항</span>
                        <span>{excel.request}</span>
                    </div>
                    <div className='work_info_wrapper'>
                        <span className={'fw-bold'}>상태</span>
                        <span className={excel.status === '성공' ? 'success' : excel.status === '실패' ? 'failed' : 'suspend'}>{excel.status}</span>
                    </div>
                    {excel.errorMsg &&
                    <div className='work_info_wrapper'>
                        <span className={'fw-bold'}>실패 사유</span>
                        <span style={{color: 'red'}}>{excel.errorMsg}</span>
                    </div>
                    }
                    <button onClick={toggle} className='work_excel_modal_close'>닫기</button>
                </div>
            </ModalBody>
        </Modal>
    )
}

WorkExcelModal.defaultProps = {
    modal: false,
    toggle: () => null,
    excel: {
        address: "",
        farmerName: "",
        farmerPhone: "",
        request: "",
        status: "",
        errorMsg: ""
    }

}
