import React, {useEffect, useState} from "react";
import closeIcon from '../../assets/icon/close.svg';
import {useDispatch, useSelector} from "react-redux";
import {setCenterLatLng, setFiltering, setProject} from "../workMode/action";
import searchIcon from '../../assets/icon/search.svg';
import filterIcon from '../../assets/icon/filter.svg';
import plusIcon from '../../assets/icon/plus.svg';
import workIcon from '../../assets/icon/add.svg';
import {getStatusColor, isIterableArray} from "../../helpers/common";
import ProjectStatusTable from "./ProjectStatusTable";
import CircularProgress from '@mui/material/CircularProgress';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {goWorkDetail, sharedLocationService, showCreateWorkDialog} from "../../helpers/MobileFunction";
import {CustomBottomSheet} from "../BottomSheets/CustomBottomSheet";
import {collection, documentId, onSnapshot, query, where} from "firebase/firestore";
import {firestore} from "../../firebase";
import {showFilterDialogAction} from "../dialog/action";
import {activeWorkAddMode, getWorkers, getWorks, setFilterAdminWorks, setFilterWorks} from "../firestore/action";
import {showSnackBarDialog} from "../snackBar/action";

const ProjectBottomSheet = ({handleSetMyLocationCenter, open, step, loader, setStep, setFocusSearch, setFocusMap}) => {
    const dispatch = useDispatch()
    const project = useSelector(state => state.workMode.project);
    const userData = useSelector(state => state.workMode.userData);
    const isFiltering = useSelector(state => state.workMode.isFiltering);
    const isActiveWorkAddMode = useSelector(state => state.firestore.isActiveWorkAddMode);
    const [centerLatLngState, setCenterLatLngState] = useState({lat: 0, lng: 0})
    const [workerSub, setWorkerSub] = useState([]);
    const handleIsAdmin = () => {
        if (Object.keys(project).length !== 0 && Object.keys(userData).length !== 0) {
            const projectUser = project?.members[userData?.id];
            if (Object.keys(projectUser).length !== 0) {
                return projectUser.authority === "관리자"
            } else {
                return false
            }
        } else {
            return false
        }
    }

    useEffect(() => {


        if (Object.keys(project).length !== 0) {
            try {
                if (project.status === "휴식" && !handleIsAdmin()) {
                    workerSub.forEach(unSubscribe => unSubscribe())
                    handleInit()
                    setStep(0);
                    return;
                }
                const q = query(collection(firestore, "works"), where("projectId", "==", project.id));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const works = [];
                    querySnapshot.forEach((doc) => {
                        const work = {
                            id: doc.id,
                            ...doc.data()
                        }
                        works.push(work);
                    });
                    if (project.memberIds) {
                        if (isIterableArray(project.memberIds)) {
                            getMyProjectWorkers(project.memberIds, project)
                        }
                    }
                    dispatch(getWorks(works));
                    dispatch(setFilterWorks(works));
                    dispatch(setFilterAdminWorks(works));
                    if (isIterableArray(works)) {
                        if (!(centerLatLngState.lat === works[0].latLng?.latitude && centerLatLngState.lng === works[0].latLng?.longitude)) {
                            const centerCoordinate = {
                                lat: works[0].latLng?.latitude,
                                lng: works[0].latLng?.longitude
                            }
                            dispatch(setCenterLatLng(centerCoordinate));
                            setCenterLatLngState(centerCoordinate)
                        }

                    }
                });

                if (step !== 1) {
                    setStep(1);
                }

                return () => {
                    unsubscribe();

                }
            } catch (err) {
                workerSub.forEach(unSubscribe => unSubscribe())
                dispatch(showSnackBarDialog(true, '프로젝트 정보를 가져오는데 실패하였습니다.'));
            }
        } else {
            workerSub.forEach(unSubscribe => unSubscribe())
            setStep(0);
        }

    }, [project])


    const getMyProjectWorkers = (projectIds, updatePr) => {
        projectIds.forEach(id => {
            const workerQuery = query(collection(firestore, "users"), where(documentId(), "==", id));

            let unsubscribe2 = onSnapshot(workerQuery, (querySnapshot) => {
                const workers = [];
                querySnapshot.forEach((doc) => {
                    const worker = {
                        id: doc.id,
                        ...doc.data()
                    }
                    workers.push(worker);
                });
                if (isIterableArray(workers)) {
                    let newWorker = workers[0]
                    dispatch(getWorkers(newWorker));
                }
            });
            setWorkerSub(prevState => [...prevState, unsubscribe2])
        })
    }

    const handleInit = () => {
        dispatch(setProject({}));
        dispatch(getWorkers({}));
        dispatch(setFilterWorks([]));
        dispatch(getWorks([]));
        dispatch(activeWorkAddMode(false));
        dispatch(setFiltering(false));
        setStep(0)
    }


    return (
        <CustomBottomSheet
            handleSetMyLocationCenter={handleSetMyLocationCenter}
            setFocusMap={setFocusMap}
            isOpen={open}>
            {loader ?
                <div className='loader_wrapper'>
                    <CircularProgress/>
                </div> :
                <div className='project_detail_wrapper'>
                    <div className='detail_header'>
                        <div>
                            <h4 className='fw-bold'>{project.name}</h4>
                            <small
                                className='fw-bold'
                                style={{color: getStatusColor(project.status)}}>{project.status}</small>
                        </div>
                        <div onClick={handleInit}>
                            <img src={closeIcon} alt={'close_icon'} width={30}/>
                        </div>
                    </div>
                    <div className='detail_action_wrapper'>
                        <span className='d-block fw-bold'>작업</span>
                        <div className='btn_list'>
                            <div
                                onClick={() => setFocusSearch(prev => !prev)}
                                className='action_btn_item'>
                                <div className='circle_img'>
                                    <img src={searchIcon} alt={'search_btn'}/>
                                </div>
                                <small>검색</small>

                            </div>
                            <div
                                onClick={() => dispatch(showFilterDialogAction(true))}
                                className='action_btn_item'>
                                <div className={isFiltering ? 'circle_img active_filter' : 'circle_img'}>
                                    <img src={filterIcon} alt={'search_btn'}/>
                                </div>
                                <small>필터</small>

                            </div>
                            <div
                                onClick={() => dispatch(activeWorkAddMode(!isActiveWorkAddMode))}
                                className='action_btn_item'>
                                <div className={isActiveWorkAddMode ? 'circle_img active_work' : 'circle_img'}>
                                    <img src={plusIcon} alt={'search_btn'}/>
                                </div>
                                <small>작업 추가</small>

                            </div>
                            {handleIsAdmin() &&
                                <div
                                    onClick={() => showCreateWorkDialog(project.id, null, null, `${userData.name} (${userData.phone})`, userData.id, handleIsAdmin())}
                                    className='action_btn_item'>
                                    <div className='circle_img'>
                                        <img src={workIcon} alt={'search_btn'}/>
                                    </div>
                                    <small>직접 추가</small>
                                </div>
                            }
                        </div>
                    </div>
                    <div className='project_detail_status'>
                        <div className={'detail_title'}>
                            <span className='fw-bold d-block'>프로젝트 상태</span>
                            <div
                                onClick={() => goWorkDetail(project.id)}
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                <span>자세히</span>
                                <ArrowForwardIosIcon sx={{fontSize: '1rem'}}/>
                            </div>
                        </div>
                        <ProjectStatusTable/>

                    </div>

                </div>
            }
        </CustomBottomSheet>
    );
};


export default React.memo(ProjectBottomSheet)
