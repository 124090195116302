import React, {useEffect, useState} from "react";
import ProjectList from "../workMode/ProjectList";
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import {collection, documentId, onSnapshot, query, where} from "firebase/firestore";
import {firestore} from "../../firebase";
import {getProjectList, setProject} from "../workMode/action";
import {CustomBottomSheet} from "../BottomSheets/CustomBottomSheet";
import {getMyLocation} from "../../helpers/MobileFunction";
import {useDispatch, useSelector} from "react-redux";
import {isIterableArray} from "../../helpers/common";
import {showSnackBarDialog} from "../snackBar/action";
import CircularProgress from "@mui/material/CircularProgress";
import {getWorkers} from "../firestore/action";


const BottomSheetBase = ({handleSetMyLocationCenter, open, setFocusMap}) => {
    const dispatch = useDispatch();
    const {project, projectList, userData} = useSelector(state => state.workMode);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        try {
            setLoader(true);
            if (userData.id) {
                const q = query(collection(firestore, "projects"),
                    where("enabled", "==", true)
                    , where("memberIds", "array-contains", userData.id));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    let projectList = []
                    querySnapshot.forEach((doc) => {
                        projectList.push({id: doc.id, ...doc.data()})
                    });
                    dispatch(getProjectList(projectList));
                    if (isIterableArray(projectList)) {
                        let memberIds = []
                        projectList.forEach(projectData => {
                            memberIds = [...memberIds, ...projectData.memberIds]
                        })
                    }
                    setLoader(false);
                });
            }
        } catch (err) {
            dispatch(showSnackBarDialog(true, '프로젝트 목록을 가져오는데 실패하였습니다.'));
            setLoader(false);
        }
    }, [userData])

    useEffect(() => {
        if (isIterableArray(projectList)) {
            if (Object.keys(project).length !== 0) {
                const existProject = projectList.filter(fP => fP.id === project.id);
                if (isIterableArray(existProject)) {
                    dispatch(setProject(existProject[0]));
                } else {
                    dispatch(setProject({}));
                }
            }
        }
    }, [projectList])





    return (

        <CustomBottomSheet
            handleSetMyLocationCenter={handleSetMyLocationCenter}
            setFocusMap={setFocusMap}
            isOpen={open}>
            <div className='bottom_sheet_wrapper'>
                {loader ?
                    <div className='flex_center' style={{marginTop: '6rem'}}>
                        <CircularProgress/>
                    </div>
                    :
                    <ProjectList/>
                }

            </div>
        </CustomBottomSheet>
    );
};


export default BottomSheetBase
