import * as types from "./actionTypes";
import axios from 'axios';



export const showSnackBarDialog = (open, message) => {
    return {
        type: types.SHOW_SNACKBAR,
        open,
        message
    }
}
