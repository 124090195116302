import Swal from "sweetalert2";
import {useLocation} from "react-router-dom";
import {utils, writeFile} from "xlsx";


export const isLogin = function () {
    const token = localStorage.getItem('auth_login');
    return !!token;
};


export const notificationAlert = (type, txt) => {
    Swal.fire({
        title: type,
        text: txt,
        confirmButtonText: '닫기'
    })
}

export const moveNotificationAlert = (type, txt, location) => {
    Swal.fire({
        allowOutsideClick: () => window.location.href = location,
        title: type,
        text: txt,
        confirmButtonText: '닫기'
    }).then(result => {
        if (result.isConfirmed) {
            window.location.href = location;
        }
    })
}

export const getStatusColor = (status) => {
    switch (status) {
        case "예정":
            return "#0cfaec"
        case "진행":
            return "#3886ff"
        case "중단":
            return "#FF4949"
        case "완료":
            return "#75D353"
        case "제외":
            return "#585858"
        case "재작업요청":
            return "#FBD82F"
        case "휴식":
            return "#E052FF"

        default:
            return "#0cfaec"
    }
}


export const getWorkStatusToKor = (status) => {
    switch (status) {
        case "expected":
            return "예정"
        case "progress":
            return "진행"
        case "stop":
            return "중단"
        case "except":
            return "제외"
        case "rework":
            return "재작업요청"
        case "complete":
            return "완료"
        default:
            return "예정"
    }
}


export const isIterableArray = array => Array.isArray(array) && !!array.length;

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}


export const downloadExcel = () => {
    const workSheet = utils.aoa_to_sheet([
        ["주소", "농주 이름", "농주 전화번호", "요청사항"]
    ]);
    const workBook = utils.book_new();
    utils.book_append_sheet(workBook, workSheet, '작업 목록');
    writeFile(workBook, 'json_to_sheet_result.xlsx');

};


export const isProjectAdmin = (project, user) => {
    if (!project || !user) {
        return
    }
    if (Object.keys(project).length !== 0) {
        if (Object.keys(user).length !== 0) {
            const userData = project.members[user.id]
            if (userData) {
                if (Object.keys(userData).length !== 0) {
                    return userData.authority === "관리자"
                }
            }
        }
    }
}


export const colorSetList = [
    "#c7d943",
    "#7b1cfd",
    "#ca948a",
    "#7e2f2b",
    "#9a8441",
    "#3182cc",
    "#7ee553",
    "#fc7770",
    "#fd02be",
    "#aef5e6",
    "#68bdc2",
    "#25556f",
    "#9da3bb",
    "#d48aa8",
    "#1114c4",
    "#62abac",
    "#db993a",
    "#220e89",
    "#93d7ce",
    "#809d85",
    "#6899de",
    "#8dfb5e",
    "#dfb415",
    "#cfc814",
    "#41ccc8",
    "#4caad7",
    "#9ef4a3",
    "#ad8cfd",
    "#2963ce",
    "#e6dd33",
    "#9a1532",
    "#5c69fb",
    "#604788",
    "#1a363a",
    "#e0bc72",
    "#7a8f16",
    "#203d19",
    "#5f7e99",
    "#3fb7d4",
    "#cf5c6c",
    "#cf551",
    "#9b9528",
    "#741c85",
    "#6573a2",
    "#f775ca",
    "#39627d",
    "#bd6240",
    "#49651d",
    "#9828ec",
    "#6f8ddd",
    "#29e5",
    "#1db7c8",
    "#9bc82",
    "#132c4",
    "#46932e",
    "#e88d58",
    "#d2518a",
    "#7ead3a",
    "#5b35b",
    "#f813ea",
    "#44cb97",
    "#bfcffb",
    "#942dd8",
    "#a98318",
    "#ca0153",
    "#b543f2",
    "#442856",
    "#594008",
    "#1eb6ab",
    "#64cbcf",
    "#b08ec8",
    "#c02c3",
    "#f0e6ab",
    "#1a255",
    "#72cd46",
    "#52bfd3",
    "#125d9a",
    "#88752f",
    "#7b835",
    "#ce8e43",
    "#86271f",
    "#7d0e33",
    "#92146a",
    "#2cee27",
    "#66bf53",
    "#7788f2",
    "#469e86",
    "#fd0d22",
    "#c38dcc",
    "#1c2a34",
    "#282cf9",
    "#76ac0a",
    "#c9bd62",
    "#a18cfb"
]