import * as types from "./actionTypes";
import axios from 'axios';
import {FILTER_PROJECT_WORKS, Filter_PROJECT_WORKS} from "./actionTypes";

export const getWorks = (workList) => {
    return {
        type: types.GET_PROJECT_WORKS,
        workList
    }
}

export const setFilterWorks = (workList) => {
    return {
        type: types.FILTER_PROJECT_WORKS,
        workList
    }
}

export const getWorkers = (worker) => {
    return {
        type: types.GET_PROJECT_WORKERS,
        worker
    }
}


export const activeWorkAddMode = (isActive) => {
    return {
        type: types.ACTIVE_WORK_ADD_MODE,
        isActive
    }
}

export const setFilterAdminWorks = (workList) => {
    return {
        type: types.FILTER_PROJECT_ADMIN_WORKS,
        workList
    }
}