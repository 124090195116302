import * as types from "./actionTypes";
import {DOWN_BOTTOM_SHEET} from "./actionTypes";


export const showFilterDialogAction = (open) => {
    return {
        type: types.SHOW_FILTER_DIALOG,
        open
    }
}


export const showPolygonDialog = (polygon) => {
    return {
        type: types.SHOW_POLYGON_DIALOG,
        polygon
    }
}

export const showUserDialog = (user) => {
    return {
        type: types.SHOW_USER_DIALOG,
        user
    }
}

export const showWorkDialog = (work) => {

    return {
        type: types.SHOW_WORK_DIALOG,
        work
    }
}


export const downBottomSheet = (down) => {
    return {
        type: types.DOWN_BOTTOM_SHEET,
        down
    }
}

export const toggleLocationUpdate = (toggle) => {
    return {
        type: types.TOGGLE_LOCATION,
        toggle
    }
}

export const showAssignWorkDialog = (open, count, area) => {
    return {
        type: types.SHOW_ASSIGN_WORK_DIALOG,
        open,
        count,
        area

    }
}

export const toggleAssignWorks = (toggle) => {
    return {
        type: types.Toggle_ASSIGN_WORK,
        toggle
    }
}

export const setUseFilterling = (toggle) => {
    return {
        type: types.USE_FILTERING,
        toggle
    }
}

export const showFilterAdminDialog = (open, members) => {
    return {
        type: types.SHOW_ADMIN_FILTER_DIALOG,
        open,
        members
    }
}