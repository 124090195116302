import React from "react";
import DialogSheet from "../common/DialogSheet";
import {useDispatch, useSelector} from "react-redux";
import {showAssignWorkDialog, toggleAssignWorks} from "./action";

const AssignWorksDialog = () => {
    const dispatch = useDispatch()
    const {showAssignWork, selectAssignWork} = useSelector(state => state.dialog);
    const handleDismissDialog = () => {
        dispatch(showAssignWorkDialog(false, 0, 0.0));
    }

    const handleOpenAssignModal = () => {
        dispatch(showAssignWorkDialog(false, 0, 0.0));
        dispatch(toggleAssignWorks(true));
    }


    return (
        <DialogSheet height={0.7} open={showAssignWork} dismiss={handleDismissDialog}>
            <div style={{padding: '1rem 1rem 0.5rem 1rem'}}>
                <span className='d-block fw-bold' style={{fontSize: '1.1rem'}}>지정된 작업 정보</span>
            </div>
            {Object.keys(selectAssignWork).length !== 0 ?
                <div className='assign_works_dialog_wrapper'>
                    <div className='d-flex justify-content-between'>
                        <div>
                            <span className='d-block'>지정된 작업 개수</span>
                            <span className='d-block mt-2'>지정된 작업 평수</span>
                        </div>
                        {Object.keys(selectAssignWork).length !== 0 &&
                        <div style={{marginLeft: '0.3rem'}}>
                            <span className='d-block fw-bold'>{selectAssignWork.count}개</span>
                            <span className='d-block fw-bold mt-2'>{selectAssignWork.area.toFixed(2)}평</span>
                        </div>
                        }
                    </div>
                    <div className={'button_wrapper'}>
                        <button onClick={handleDismissDialog}>취소하기</button>
                        <button onClick={handleOpenAssignModal}>작업 할당하기</button>
                    </div>
                </div>
                : <></>
            }
        </DialogSheet>
    )
}


export default AssignWorksDialog
