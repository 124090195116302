import React, {useState} from "react";
import {Modal, ModalBody} from "reactstrap";
import {isIterableArray} from "../../../helpers/common";
import {useDispatch, useSelector} from "react-redux";
import {setUseFilterling, toggleAssignWorks} from "../../dialog/action";
import {firestore} from "../../../firebase";
import {doc, writeBatch} from "firebase/firestore";
import {showSnackBarDialog} from "../../snackBar/action";
import {CircularProgress} from "@mui/material";

const AssignmentStatusModal = ({works, members}) => {
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    const {toggleShowAssignWorks} = useSelector(state => state.dialog);
    const [selectMember, setSelectMember] = useState({});

    // const checkAlreayWorker = (works) => {
    //     return new Promise(resolve => {
    //         if (isIterableArray(works)) {
    //             const workerIdList = works.filter(work => {
    //                 if (work.workerId) {
    //                     return true
    //                 }
    //             });
    //             if (isIterableArray(workerIdList)) {
    //                 resolve(false);
    //             } else {
    //                 resolve(true);
    //             }
    //         }
    //     })
    // }

    const handleAssignmentWorks = async () => {
        try {
            setLoader(true);
            if (Object.keys(selectMember).length === 0) {
                dispatch(showSnackBarDialog(true, '작업을 할당할 멤버를 선택하여 주세요.'));
                return;
            }
            const batch = writeBatch(firestore);
            if (isIterableArray(works)) {
                // const checkResult = await checkAlreayWorker(works);
                // if (checkResult) {
                    works.forEach(work => {
                        const workRef = doc(firestore, "works", work.id);

                        batch.update(workRef, {
                            worker: `${selectMember.name} (${selectMember.phone})`,
                            workerId: selectMember.id
                        });
                    })
                // } else {
                //     setLoader(false)
                //     dispatch(showSnackBarDialog(true, '이미 작업자가 할당된 작업이 포함되어 있습니다. 다시 지정하여 주세요.'));
                //     return;
                // }

            }


            await batch.commit();
            setLoader(false)
            dispatch(showSnackBarDialog(true, '작업 할당이 완료되었습니다.'));
            toggle()
            dispatch(setUseFilterling(false))
        } catch (err) {
            if (err) {
                setLoader(false);
                dispatch(showSnackBarDialog(true, '작업 할당에 문제가 발생하였습니다.'));
            }
        }
    }

    const toggle = () => {
        dispatch(toggleAssignWorks(false));
    }

    const handleChangeWorker = (member) => {
        const parseMember = JSON.parse(member)
        setSelectMember({id: parseMember.id, name: parseMember.name, phone: parseMember.phone})

    }

    return (
        <Modal centered isOpen={toggleShowAssignWorks} toggle={toggle} className={'work_assign_status_modal'}>
            <ModalBody className={'p-0'}>
                <div className={'assignment_status_modal'}>
                    <div className={'modal_header'}>
                        <span>작업자 할당</span>
                    </div>
                    <div className={'modal_content'}>
                        {isIterableArray(members) &&
                            <select onChange={event => {
                                handleChangeWorker(event.target.value)
                            }}>
                                <option>작업자를 선택하여 주세요.</option>
                                {members.map((member, index) => <option key={index} value={JSON.stringify(member)}>
                                    {`${member.name} (${member.phone})`}
                                </option>)}
                            </select>}
                    </div>
                    <div className={'bottom_btn_wrapper'}>
                        <button onClick={toggle}>취소하기</button>
                        <button disabled={loader} onClick={handleAssignmentWorks}>
                            {loader ?
                                <CircularProgress style={{width: '15px', height: '15px', color: '#3886ff'}}/>
                                :
                                '작업할당'
                            }
                        </button>
                    </div>
                </div>

            </ModalBody>
        </Modal>

    )
}


export default AssignmentStatusModal