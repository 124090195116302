import {initializeApp} from "firebase/app"
import {getFirestore} from 'firebase/firestore';
import "firebase/firestore";
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
    authDomain: "bluesky-d6001.firebaseapp.com",
    projectId: "bluesky-d6001",
    storageBucket: "bluesky-d6001.appspot.com",
    messagingSenderId: "746771528457",
    appId: "1:746771528457:web:a00ebf40b9a65064c771d8",
    measurementId: "G-XDBXKZ4HS2"
};


const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const firestore = getFirestore(app);

export {firestore, functions};
