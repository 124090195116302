import {useEffect, useRef, useState} from "react";
import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import {isIterableArray} from "../../helpers/common";
import {useDispatch, useSelector} from "react-redux";
import {setShowAssignModal} from "./action";

export const Debounce = ({focusSearch, focusMap, handleSearchLocation}) => {
    const {
        placePredictions,
        getPlacePredictions,
        isPlacePredictionsLoading,
    } = useGoogle({
        apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
        options: {
            types: ['geocode'],
            componentRestrictions: {
                country: 'kr'
            }
        },
        language: 'kr'
    });
    const [value, setValue] = useState("");
    const [show, setShow] = useState(false);
    const [isAdminUser, setIsAdminUser] = useState(false);
    const searchBarRef = useRef(null);
    const dispatch = useDispatch();
    const showAssignModal = useSelector(state => state.workMode.showAssignModal);
    const project = useSelector(state => state.workMode.project);
    const projectList = useSelector(state => state.workMode.projectList);
    const userData = useSelector(state => state.workMode.userData);


    useEffect(() => {
        searchBarRef.current.blur();
        setShow(false)
    }, [focusMap])

    useEffect(() => {
        searchBarRef.current.focus();
    }, [focusSearch])

    useEffect(() => {
        if (isIterableArray(projectList)) {
            if (Object.keys(project).length !== 0) {
                const projectData = projectList.filter(pr => pr.id === project.id);
                if (isIterableArray(projectData)) {
                    const userMemberData = projectData[0].members[userData.id];
                    if (userMemberData) {
                        if (userMemberData.authority !== '관전자') {
                            setIsAdminUser(true)
                        } else {
                            setIsAdminUser(false)
                        }
                    }
                }

            }
        }
    }, [project, userData, projectList])


    return (
        <div className='google_search_bar_wrapper no_drag' style={{width: "100%"}}>
            <Paper
                component="form"
                sx={{p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%'}}
            >
                <InputBase
                    onFocus={() => setShow(true)}
                    type={'search'}
                    inputRef={searchBarRef}
                    onKeyPress={event => {
                        if (event.key === "Enter") {
                            event.preventDefault()
                        }
                        if (event.code === 'Enter') {
                            event.preventDefault()
                        }
                        if (event.keyCode === 13) {
                            event.preventDefault()
                        }
                    }}
                    value={value}
                    sx={{ml: 1, flex: 1}}
                    placeholder="주소를 입력하여 주세요."
                    onChange={(evt) => {
                        if (!show) setShow(true);
                        if (evt.target.value.length === 0) setShow(false);
                        getPlacePredictions({input: evt.target.value});
                        setValue(evt.target.value);
                    }}
                    inputProps={{'aria-label': 'search google maps'}}
                />
                <div style={{padding: '10px'}}>
                    <SearchIcon/>
                </div>
            </Paper>

            <div
                className='search_result_wrapper'
                style={{

                    backgroundColor: 'white',
                    marginTop: "5px",
                    width: "100%",
                    display: "flex",
                    flex: "1",
                    flexDirection: "column",
                }}
            >
                {(!isPlacePredictionsLoading && show) && <div>
                    <ul className={isIterableArray(placePredictions) ? 'place_list_wrapper show_border' : 'place_list_wrapper'}>
                        {value !== "" &&
                            <li
                                onClick={async () => {
                                    setValue(value);
                                    const searchResult = await handleSearchLocation(value);
                                    if (searchResult) setValue('');
                                    setShow(false);
                                }}
                            >
                                <h4>{value}로 검색하기</h4>
                            </li>
                        }
                        {placePredictions.map((place, index) => <li
                            key={index}
                            onClick={async () => {
                                const address = place.description?.replace("대한민국 ", '')
                                setValue(address);
                                const searchResult = await handleSearchLocation(address);
                                if (searchResult) setValue('');
                                setShow(false);
                            }}>
                            <h4>{place.description?.replace("대한민국 ", '')}</h4>
                        </li>)}
                    </ul>

                </div>
                }

            </div>

            {(Object.keys(project).length !== 0 && isAdminUser) &&
                <button
                    onClick={() => dispatch(setShowAssignModal(!showAssignModal))}
                    id={'assign_active_modal_btn'}
                    className={'d-block'}
                    style={{marginBottom: '0.3rem'}}
                >
                        <span>
                          작업할당 모드
                        </span>
                </button>
            }

        </div>
    );
};
