import React, {useCallback, useEffect} from "react";
import {Marker, MarkerClusterer, Polygon,} from '@react-google-maps/api';
import {getStatusColor, isIterableArray} from "../../helpers/common";
import {useDispatch, useSelector} from "react-redux";
import {showWorkDialog} from "../dialog/action";


const WorksPolygon = () => {
    const dispatch = useDispatch();
    const filterWorkList = useSelector(state => state.firestore.filterWorkList);


    return (
        <>
            {isIterableArray(filterWorkList) &&
                filterWorkList.map((work, index) => {
                    const workPath = work.polygon.map(userPath => ({lat: userPath.latitude, lng: userPath.longitude}))
                    return (
                        <Polygon
                            key={index}
                            onClick={() => dispatch(showWorkDialog(work))}
                            path={workPath}
                            options={
                                {
                                    fillColor: getStatusColor(work.workStatus),
                                    strokeColor:  'white',
                                    strokeWeight: work.workStatus === '예정' ? 4 : 1,
                                    fillOpacity: work.workStatus === '예정' ? 0 : 0.7
                                }
                            }/>
                    )
                })

            }
            {isIterableArray(filterWorkList) &&
                <MarkerClusterer
                    maxZoom={13}>
                    {(markerCluster) => (
                        filterWorkList.map((work, index) =>
                            <Marker
                                key={index}
                                clusterer={markerCluster}
                                position={{lat: work.latLng.latitude, lng: work.latLng.longitude}}
                                visible={false}/>
                        )
                    )
                    }
                </MarkerClusterer>
            }
        </>
    )
}

export default React.memo(WorksPolygon)



