

export const GET_PROJECT_WORKERS = 'GET_PROJECT_WORKERS';

export const GET_PROJECT_WORKS = 'GET_PROJECT_WORKS';

export const FILTER_PROJECT_WORKS = 'FILTER_PROJECT_WORKS';

export const ACTIVE_WORK_ADD_MODE = 'ACTIVE_WORK_ADD_MODE';

export const FILTER_PROJECT_ADMIN_WORKS = 'FILTER_PROJECT_ADMIN_WORKS';