import React, {Fragment} from "react";
import {Marker} from '@react-google-maps/api';
import {useDispatch, useSelector} from "react-redux";
import workerIcon from "../../assets/icon/workerIcon.svg";
import {showUserDialog} from "../dialog/action";


const WorkersMarker = () => {
    const dispatch = useDispatch();
    const workerList = useSelector(state => state.firestore.workerList);


    return (
        <>
            {Object.keys(workerList).length !== 0 &&
                Object.keys(workerList).map((workerId, index) => {
                    const workerData = workerList[workerId]
                    if (workerData) {
                        if (workerData.latLng) {
                            return (
                                <Marker
                                    key={index}
                                    icon={{
                                        url: workerIcon,
                                    }}
                                    onClick={() => dispatch(showUserDialog(workerData))}
                                    index={index}
                                    position={{lat: workerData.latLng.latitude, lng: workerData.latLng.longitude}}/>
                            )
                        } else {
                            return (
                                <Fragment key={index}/>
                            )
                        }
                    } else {
                        return (
                            <Fragment key={index}/>
                        )
                    }
                })}
        </>
    )
}


export default WorkersMarker



